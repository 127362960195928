












































// Import vendors ----------------------------------------------------------------------------------
import { computed, defineComponent, onMounted, ref, watch } from '@vue/composition-api';
import { get } from 'lodash';
// Import plugins ----------------------------------------------------------------------------------
import { usePodocoreModuleService } from '@/plugins/podocore';
// Import utils ------------------------------------------------------------------------------------
import { useLocalStorage } from '@vueuse/core';
import { useFormFieldText } from '@/utils/forms.utils';
// Import components -------------------------------------------------------------------------------
import CompositeDialog from '@/components/composite/CompositeDialog.vue';
import FormFieldCheckbox from '@/components/forms/FormFieldCheckbox.vue';
import ExportationViewer from '@/components/exportation/ExportationViewer.vue';
// Import types ------------------------------------------------------------------------------------
import type { DialogId } from '@/plugins/podocore/modules/bus/bus.module';
// -------------------------------------------------------------------------------------------------

export type TExportAnalysis = {
  informations: TExportAnalysisInformations | TExportAnalysisInformationsComparison;
  standards: any;
};

export type TExportAnalysisInformations = {
  analysisType: string;
  analysisDate: string;
  valid: number;
};
export type TExportAnalysisInformationsComparison = {
  first: TExportAnalysisInformations;
  second: TExportAnalysisInformations;
};

export default defineComponent({
  name: 'DialogExportPDF',
  components: {
    CompositeDialog,
    FormFieldCheckbox,
    ExportationViewer
  },
  setup() {
    // Dialog
    const dialogId: DialogId = 'analysis-export-pdf';

    // References
    const rDialog = ref<any>(null);
    const rFrame = ref<any>(null);

    // Services
    const workspacesService = usePodocoreModuleService('workspaces');

    const scenario = computed(() => get(rDialog, 'value.meta.scenario', null));
    const analysis = computed(() => get(rDialog, 'value.meta.analysis', null));
    const patient = computed(() => get(rDialog, 'value.meta.patient', null));
    const mode = computed(() => get(rDialog, 'value.meta.mode', 'standard'));

    const comparisonMode = computed(() => mode.value === 'comparison');

    const currentWorkspace = computed(() => {
      return (workspacesService.state.value as any).context.current;
    });

    const printDialogIsOpened = ref<boolean>(false);

    // Form
    let form = ref<any>(null);

    // Form cache
    const formCache = useLocalStorage('exportPdf', {
      advanced: false,
      showEmail: true,
      showPhone: true
    });

    onMounted(() => {
      form.value = {
        advanced: useFormFieldText<boolean>({
          key: 'advanced',
          value: formCache.value.advanced,
          rules: {}
        }),
        showEmail: useFormFieldText<boolean>({
          key: 'show-email',
          value: formCache.value.showEmail,
          rules: {}
        }),
        showPhone: useFormFieldText<boolean>({
          key: 'show-phone',
          value: formCache.value.showPhone,
          rules: {}
        })
      };
    });

    watch(
      form,
      (value: any) => {
        formCache.value = {
          advanced: value.advanced.value,
          showEmail: value.showEmail.value,
          showPhone: value.showPhone.value
        };
      },
      { deep: true }
    );

    function print() {
      // ExportationViewer
      let dialog = rDialog.value.$children[0].$children[0].$children[0];
      dialog = dialog.$children[dialog.$children.length - 1];
      dialog.print();
    }

    return {
      // References
      rFrame,
      rDialog,
      // Dialog
      dialogId,
      // analysis
      currentWorkspace,
      form,
      scenario,
      analysis,
      patient,
      comparisonMode,
      // Methods
      print,
      // Flags
      printDialogIsOpened
    };
  }
});
