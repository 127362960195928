












// Import vendors ----------------------------------------------------------------------------------
import { defineComponent } from '@vue/composition-api';
// Import components -------------------------------------------------------------------------------
import ChartRunningWidgetSpeed from '@/components/charts/running/widgets/ChartRunningWidgetSpeed.vue';
import ChartRunningWidgetCadence from '@/components/charts/running/widgets/ChartRunningWidgetCadence.vue';
import ChartRunningWidgetAnalysisMode from '@/components/charts/running/widgets/ChartRunningWidgetAnalysisMode.vue';
import ChartRunningWidgetVLR from '@/components/charts/running/widgets/ChartRunningWidgetVLR.vue';
// -------------------------------------------------------------------------------------------------

export default defineComponent({
  name: 'ChartRunningContainerGroupBanner',
  components: {
    ChartRunningWidgetSpeed,
    ChartRunningWidgetCadence,
    ChartRunningWidgetAnalysisMode,
    ChartRunningWidgetVLR
  },
  props: {
    aggregates: {
      type: Object,
      required: true
    },
    norms: {
      type: Object,
      required: true
    },
    displayMetrics: {
      type: Object,
      required: true
    },
    simple: {
      type: Boolean
    }
  }
});
