



















































































































































// Import vendors ----------------------------------------------------------------------------------
import { defineComponent, ref, reactive, computed, watch, toRefs, Ref, PropType } from '@vue/composition-api';
// Import plugins ----------------------------------------------------------------------------------
import { usePodocoreModule } from '@/plugins/podocore';
import { usePodocoreModuleService } from '@/plugins/podocore';
// Import helpers ----------------------------------------------------------------------------------
import { downloadPdf } from '@/helpers/helped-functions.helper';
import { dateFormatRelatedToSelectedLanguage } from '@/helpers/basic-values.helper';
// Import utils ------------------------------------------------------------------------------------
import { useRoute } from '@/utils/router.utils';
import { useCDN } from '@/utils/cdn.utils';
import { useRehab } from '@/utils/rehab.utils';
// Import types ------------------------------------------------------------------------------------
import type { Entity } from '@/plugins/podocore/helpers/repositories.helper';
import type { Patient } from '@/plugins/podocore/repositories/patients.repository';
import { AnalysisType } from '@/plugins/podocore/repositories/analysis.repository';
import { MediaType } from '@/utils/cdn.utils';
import { SupportedLang } from '@/utils/i18n.utils';
import type { AnalysisEntityCompleted } from '@digitsole/blackburn-entities/dist/entities/analysis/analysis-completed.entity';
// -------------------------------------------------------------------------------------------------

export enum EAnalysisName {
  Walking = 'walk',
  Running = 'run',
  Jumping = 'jumping'
}
export enum EAnalysisType {
  Walking = 'walking',
  Running = 'running',
  Jumping = 'jumping'
}
export enum EPathology {
  Default = 'default',
  Neurological = 'neurological',
  Traumatology = 'traumatology',
  Reathleticism = 're-athleticism',
  Custom = 'custom'
}
export type TAnalysisNames = {
  [AnalysisType.walking]: EAnalysisName.Walking;
  [AnalysisType.running]: EAnalysisName.Running;
  [AnalysisType.jumping]: EAnalysisName.Jumping;
};
export type TAnalysisType = {
  [AnalysisType.walking]: EAnalysisType.Walking;
  [AnalysisType.running]: EAnalysisType.Running;
  [AnalysisType.jumping]: EAnalysisType.Jumping;
};
export type TPathologyColors = {
  [EPathology.Default]: TPathologyColor;
  [EPathology.Neurological]: TPathologyColor;
  [EPathology.Traumatology]: TPathologyColor;
  [EPathology.Reathleticism]: TPathologyColor;
  [EPathology.Custom]: TPathologyColor;
};
export type TPathologyColor = {
  color: string;
  background: string;
  icon: string;
};

export enum EResultsTab {
  General = 'general',
  Advanced = 'advanced'
}

export default defineComponent({
  name: 'PatientResultsHeader',
  props: {
    analysis: {
      type: Object as PropType<AnalysisEntityCompleted>,
      required: true
    },
    scenario: {
      type: Object,
      required: true
    },
    patient: {
      type: Object as PropType<Entity<Patient>>,
      required: true
    },
    degradedAnalysis: {
      type: Boolean
    }
  },
  setup(properties, { root, emit }) {
    const { analysis } = toRefs(properties);
    const { getMode } = useRehab();
    const formatedDate = dateFormatRelatedToSelectedLanguage();

    const analysisType = properties.scenario.key;

    const nameByAnalysisType: TAnalysisNames = reactive({
      [AnalysisType.walking]: EAnalysisName.Walking,
      [AnalysisType.running]: EAnalysisName.Running,
      [AnalysisType.jumping]: EAnalysisName.Jumping
    });
    const refactoredAnalysisType: TAnalysisType = reactive({
      [AnalysisType.walking]: EAnalysisType.Walking,
      [AnalysisType.running]: EAnalysisType.Running,
      [AnalysisType.jumping]: EAnalysisType.Jumping
    });

    const { query } = useRoute();
    const { cdn } = useCDN();
    const busModule = usePodocoreModule('bus');

    const isWalking = computed(() => analysisType.startsWith('walking'));
    const isRunning = computed(() => analysisType.startsWith('running'));
    const isRehab = computed(() => analysisType.startsWith('jumping'));

    // Steps
    const resultsTab = ref<EResultsTab>(EResultsTab.General);
    const steps = computed(() => {
      if (isRunning.value) {
        return [
          {
            id: 'general',
            text: root.$t('commons.standards.overview') as string
          },
          {
            id: 'advanced',
            text: root.$tc('commons.standards.analysis', 1) as string
          }
        ];
      }

      return [
        {
          id: 'general',
          text: root.$tc('commons.standards.general', 2) as string
        },
        {
          id: 'advanced',
          text: root.$t('commons.standards.angular') as string
        }
      ];
    });
    watch(resultsTab, (_tab) => {
      emit('setResultsTab', _tab);
    });

    // Pathologies filter
    const selectedPathology: Ref<EPathology> = ref(EPathology.Default);
    const pathologyFilters: Ref<EPathology[]> = ref([
      EPathology.Default,
      EPathology.Neurological,
      EPathology.Traumatology,
      EPathology.Reathleticism,
      EPathology.Custom
    ]);
    const colorsByPathologies: TPathologyColors = reactive({
      [EPathology.Default]: {
        color: '#1D253C',
        background: '#F5F6F9',
        icon: '#FFFFFF'
      },
      [EPathology.Neurological]: {
        color: '#CD4ED0',
        background: 'rgba(205,78,208,0.05)',
        icon: '#CD4ED0'
      },
      [EPathology.Traumatology]: {
        color: '#E8A500',
        background: 'rgba(255,189,26,0.05)',
        icon: '#FFBD1A'
      },
      [EPathology.Reathleticism]: {
        color: '#A376DC',
        background: 'rgba(163,118,220,0.05)',
        icon: '#A376DC'
      },
      [EPathology.Custom]: {
        color: '#1D253C',
        background: '#F5F6F9',
        icon: '#FFFFFF'
      }
    });
    const isDefaultPathology: Ref<boolean> = computed(() => selectedPathology.value === EPathology.Default);
    watch(selectedPathology, (_pathology) => {
      emit('setSelectedPathology', _pathology);
    });

    // Interpretation manual
    const analysisTypeCode = computed(() => (isWalking ? '010' : '020'));
    const manual = computed(() => {
      return {
        url: cdn(
          MediaType.Documents,
          `PS_0001_IFU_${analysisTypeCode.value}_REV02_${currentLang.value.toUpperCase()}.pdf`
        ),
        fileName: `interpretation-manual-${analysisType}`
      };
    });

    // Current lang
    const doctorService = usePodocoreModuleService('doctor');
    const currentLang = computed<SupportedLang>(() => {
      const lang = (doctorService?.state.value as any).context.doctor.config.lang;
      return lang === SupportedLang.En || lang === SupportedLang.Fr ? lang : SupportedLang.En;
    });

    // Comparison
    function openComparison() {
      if (query.value?.firstAnalysis != analysis.value.cuid) {
        root.$router.push({
          name: 'patient-comparison',
          query: {
            firstAnalysis: analysis.value.cuid
          }
        });
      }
    }
    // PDF export
    function openExport() {
      if (analysis.value) {
        busModule.publish(
          busModule.events.openDialog({
            id: 'analysis-export-pdf',
            meta: {
              scenario: properties.scenario.key,
              analysis: analysis.value,
              patient: properties.patient
            }
          })
        );
      }
    }
    // Delete analysis
    function openDelete() {
      busModule.publish(
        busModule.events.openDialog({
          id: 'analysis-delete',
          meta: { analysisCuid: analysis.value.cuid }
        })
      );
    }
    // Custom results page
    const customSelectedChart = ref([]);
    function openCustomPathologies() {
      busModule.publish(
        busModule.events.openDialog({
          id: 'analysis-pathology-custom-select',
          meta: { selectedCharts: customSelectedChart.value }
        })
      );
      if (customSelectedChart.value.length) customSelectedChart.value = [];
    }

    return {
      // Values
      formatedDate,
      nameByAnalysisType,
      refactoredAnalysisType,
      analysisType,
      resultsTab,
      steps,
      selectedPathology,
      pathologyFilters,
      colorsByPathologies,
      isDefaultPathology,
      manual,
      isWalking,
      isRunning,
      isRehab,
      // Methods
      openComparison,
      openExport,
      openDelete,
      openCustomPathologies,
      getMode,
      // Helpers
      downloadPdf
    };
  }
});
