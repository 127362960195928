var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"page__evolution__banner"},[_vm._l((_vm.ScenarioKey),function(scenario){return _c('div',{key:scenario},[(scenario === _vm.ScenarioKey.JumpingEmbedded)?_c('VMenu',{staticClass:"ml-5",attrs:{"content-class":"elevation-3","offset-y":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var menu = ref.on;
return [_c('div',_vm._g({staticClass:"d-flex align-center",class:[
            ("analysis-type analysis-type__button analysis-type__" + (_vm.refactoredAnalysisType[scenario.split('_')[0]]) + " my-4"),
            { active: scenario === _vm.scenarioKey }
          ]},menu),[_c('div',{staticClass:"analysis-type__icon analysis-type__jumping__icon"},[_c('img',{attrs:{"src":require("@/assets/images/rehab-icon.svg"),"height":"12","alt":"rehab-icon"}})]),_c('div',{staticClass:"analysis-type__name analysis-type__jumping__name"},[_vm._v(" "+_vm._s(_vm.jumpingModeKey ? _vm.$t(("commons.standards." + (_vm.getJumpingModeType(_vm.jumpingModeKey)))) : _vm.$t('commons.standards.jumping'))+" ")]),_c('GlobalIcon',{staticClass:"ml-1",attrs:{"icon":"angle-down","color":"black","size":"16"}})],1)]}}],null,true)},[_c('VList',{attrs:{"dense":""}},_vm._l((_vm.jumpingModes),function(mode){return _c('VListItem',{key:mode.key,on:{"click":function($event){_vm.jumpingModeKey = mode.key}}},[_vm._v(_vm._s(_vm.$t(("commons.standards." + (mode.type)))))])}),1)],1):_c('div',{class:[
        ("analysis-type analysis-type__button analysis-type__" + (_vm.refactoredAnalysisType[scenario.split('_')[0]]) + " my-4"),
        { active: scenario === _vm.scenarioKey }
      ],on:{"click":function($event){return _vm.onWalkRunButtonClick(scenario)}}},[_c('div',{class:("analysis-type__icon analysis-type__" + (_vm.refactoredAnalysisType[scenario.split('_')[0]]) + "__icon")},[_c('GlobalIcon',{attrs:{"icon":_vm.refactoredAnalysisType[scenario.split('_')[0]],"color":"white","size":"12"}})],1),_c('div',{class:("analysis-type__name analysis-type__" + scenario + "__name")},[_vm._v(" "+_vm._s(_vm.$t(("commons.standards." + (_vm.nameByAnalysisType[scenario.split('_')[0]]))))+" ")])])],1)}),_c('VSpacer'),(_vm.degradedAnalysesNumber)?_c('VBtn',{attrs:{"small":"","depressed":""},on:{"click":_vm.toggleHideDegradedAnalyses}},[_vm._v(_vm._s(_vm.hideDegradedAnalyses ? 'Without degraded' : 'With degraded')+" ("+_vm._s(_vm.degradedAnalysesNumber)+")")]):_vm._e(),(_vm.scenarioKey !== _vm.ScenarioKey.JumpingEmbedded)?_c('ElementResultsSwitchRelative',{model:{value:(_vm.relative),callback:function ($$v) {_vm.relative=$$v},expression:"relative"}}):_vm._e()],2)}
var staticRenderFns = []

export { render, staticRenderFns }