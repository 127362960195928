<template>
  <div class="chart" :style="`height: ${height}`">
    <div v-if="!hideTitle" class="chart__title">{{ $t('commons.sentences.angle-pro-sup') }}</div>
    <div v-if="analysisDate" class="chart__date">{{ $moment(analysisDate).format('LLL') }}</div>
    <div class="chart__content">
      <div class="chart">
        <ChartRunningPronationSupinationAnglesAdvanced
          v-if="
            advancedMode &&
            displayMetrics.aggregates.running_propulsion_time &&
            displayMetrics.aggregates.running_proportion_propulsion_time &&
            displayMetrics.aggregates.running_loading_time &&
            displayMetrics.aggregates.running_proportion_loading_time &&
            displayMetrics.aggregates.running_pronation_angle_foot_in &&
            displayMetrics.aggregates.running_pronation_angle_foot_out &&
            displayMetrics.aggregates.running_pronation_angle_mid
          "
          v-bind="{ height, aggregates }"
          :relative="false"
        />
        <div v-else>
          <ChartRunningGroupStepsAngles
            v-if="
              displayMetrics.aggregates.running_pronation_angle_foot_in &&
              displayMetrics.aggregates.running_pronation_angle_foot_out &&
              displayMetrics.aggregates.running_pronation_angle_mid
            "
            :values="aggregates"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
// Import vendors ----------------------------------------------------------------------------------
import { defineComponent } from '@vue/composition-api';
// Import components -------------------------------------------------------------------------------
import ChartRunningGroupStepsAngles from '@/components/charts/running/groups/ChartRunningGroupStepsAngles.vue';
import ChartRunningPronationSupinationAnglesAdvanced from '@/components/charts/running/ChartRunningPronationSupinationAnglesAdvanced.vue';
// -------------------------------------------------------------------------------------------------

export default defineComponent({
  name: 'ExportationFrameRunningChartPronationAngles',
  components: {
    ChartRunningGroupStepsAngles,
    ChartRunningPronationSupinationAnglesAdvanced
  },
  props: {
    aggregates: {
      type: Object,
      required: true
    },
    displayMetrics: {
      type: Object,
      required: true
    },
    height: {
      type: Number,
      required: true
    },
    advancedMode: {
      type: Boolean,
      required: true
    },
    analysisDate: {
      type: String
    },
    hideTitle: {
      type: Boolean
    }
  }
});
</script>

<style lang="scss" scoped>
.chart {
  &__title {
    font-size: 1rem;
  }

  &__content {
    margin-top: 20px;

    .chart {
      position: relative;
      .fill-width {
        width: 100%;
      }
    }
  }
}
</style>
