












// Import vendors ----------------------------------------------------------------------------------
import { defineComponent, computed, PropType } from '@vue/composition-api';
// Import components -------------------------------------------------------------------------------
import ChartEvolutionContainer from '@/components/charts/ChartEvolutionContainer.vue';
// Import plugins ----------------------------------------------------------------------------------
import i18n from '@/plugins/i18n';
// Import utils - ----------------------------------------------------------------------------------
import { useChart } from '@/utils/charts.utils';
import { EThemeColor } from '@/utils/charts.utils';
import { EUnitKey } from '@/utils/parameter-units.utils';
// Import types ------------------------------------------------------------------------------------
import type { TDataStandard } from '@/components/patient/PatientEvolution.vue';
// -------------------------------------------------------------------------------------------------

export default defineComponent({
  name: 'ChartEvolutionRehabSide',
  components: {
    ChartEvolutionContainer
  },
  props: {
    title: {
      type: String,
      required: true
    },
    data: {
      type: Object,
      required: true
    },
    unit: {
      type: String as PropType<EUnitKey>,
      required: true
    },
    round: {
      type: Number,
      default: 0
    },
    manifest: {
      type: Array
    }
  },
  setup(properties) {
    const { evolutionChartStructure, getSideData, getSideDataVariations, getSerieItemStructure } = useChart();

    const data: TDataStandard = computed(() => getSideData(properties.data));

    const snakeCaseTitle = computed(() =>
      properties.title
        ? properties.title
            .replace(/\W+/g, ' ')
            .split(/ |\B(?=[A-Z])/)
            .map((word) => word.toLowerCase())
            .join('_')
        : 'undefined'
    );

    const dataVariations = computed(() =>
      getSideDataVariations({ left: data.value.left.values, right: data.value.right.values })
    );

    const valid = computed(
      () =>
        data.value.left.values.filter((x: number | null) => x).length &&
        data.value.right.values.filter((x: number | null) => x).length
    );

    const series = computed(() => {
      return [
        // Left
        getSerieItemStructure(
          `${snakeCaseTitle.value}_left`,
          i18n.t('commons.standards.left') as string,
          data.value.left.values,
          EThemeColor.Chart1
        ),
        getSerieItemStructure(
          `${snakeCaseTitle.value}_left`,
          undefined,
          data.value.left.ranges,
          EThemeColor.Chart1,
          true
        ),
        // Right
        getSerieItemStructure(
          `${snakeCaseTitle.value}_right`,
          i18n.t('commons.standards.right') as string,
          data.value.right.values,
          EThemeColor.Chart2
        ),
        getSerieItemStructure(
          `${snakeCaseTitle.value}_right`,
          undefined,
          data.value.right.ranges,
          EThemeColor.Chart2,
          true
        )
      ];
    });

    const chartOptions = computed(() => {
      return {
        ...evolutionChartStructure(
          i18n.t(`commons.standards.${properties.title}`) as string,
          properties.manifest,
          properties.unit,
          properties.round
        ),
        series: series.value
      };
    });

    return {
      valid,
      dataVariations,
      chartOptions
    };
  }
});
