








// Import vendors ----------------------------------------------------------------------------------
import { defineComponent, computed } from '@vue/composition-api';
// Import components -------------------------------------------------------------------------------
import ChartEvolutionContainer from '@/components/charts/ChartEvolutionContainer.vue';
// Import plugins ----------------------------------------------------------------------------------
import i18n from '@/plugins/i18n';
// Import utils - ----------------------------------------------------------------------------------
import { useChart, EThemeColor } from '@/utils/charts.utils';
import { EUnitKey } from '@/utils/parameter-units.utils';
// Import types ------------------------------------------------------------------------------------
import type { TDataStandard } from '@/components/patient/PatientEvolution.vue';
// -------------------------------------------------------------------------------------------------

export default defineComponent({
  name: 'ChartEvolutionToesOff',
  components: {
    ChartEvolutionContainer
  },
  props: {
    manifest: {
      type: Array,
      required: true
    },
    toesOff: {
      type: Object
    },
    norms: {
      type: Object
    }
  },
  setup(properties) {
    const {
      evolutionChartStructure,
      getSideData,
      getSideDataVariations,
      getSerieItemStructure,
      getNormsStructure
    } = useChart();

    const title = 'commons.standards.toe-off';

    const toesOff: TDataStandard = computed(() => getSideData(properties.toesOff));

    const dataVariations = computed(() =>
      getSideDataVariations({ left: toesOff.value.left.values, right: toesOff.value.right.values })
    );

    const series = computed(() => {
      let data = [
        // Left
        getSerieItemStructure(
          'toes_off_left',
          `${i18n.t('commons.standards.toe-off')} (${(i18n.t('commons.standards.left') as string).slice(
            0,
            1
          )})`,
          toesOff.value.left.values,
          EThemeColor.Chart1
        ),
        getSerieItemStructure(
          'toes_off_left',
          undefined,
          toesOff.value.left.ranges,
          EThemeColor.Chart1,
          true
        ),
        // Right
        getSerieItemStructure(
          'toes_off_right',
          `${i18n.t('commons.standards.toe-off')} (${(i18n.t('commons.standards.right') as string).slice(
            0,
            1
          )})`,
          toesOff.value.right.values,
          EThemeColor.Chart2
        ),
        getSerieItemStructure(
          'toes_off_right',
          undefined,
          toesOff.value.right.ranges,
          EThemeColor.Chart2,
          true
        )
      ];
      // Have norms
      if (properties.norms)
        data.push(getNormsStructure('toes_off_norms', properties.norms as { min: number[]; max: number[] }));

      return data;
    });

    const chartOptions = computed(() => {
      return {
        ...evolutionChartStructure(title, properties.manifest, EUnitKey.Degree, 1),
        series: series.value
      };
    });

    return {
      title,
      dataVariations,
      chartOptions
    };
  }
});
