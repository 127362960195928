














// Import vendors ----------------------------------------------------------------------------------
import { defineComponent, computed, PropType } from '@vue/composition-api';
// Import components -------------------------------------------------------------------------------
import ChartEvolutionContainer from '@/components/charts/ChartEvolutionContainer.vue';
// Import plugins ----------------------------------------------------------------------------------
import i18n from '@/plugins/i18n';
// Import utils ------------------------------------------------------------------------------------
import { useChart, EThemeColor } from '@/utils/charts.utils';
import { EUnitKey } from '@/utils/parameter-units.utils';
import { useUnit } from '@/utils/unit.utils';
// -------------------------------------------------------------------------------------------------

export default defineComponent({
  name: 'ChartEvolutionSpeed',
  components: {
    ChartEvolutionContainer
  },
  props: {
    manifest: {
      type: Array as PropType<string[]>,
      required: true
    },
    speed: {
      type: Object
    },
    norms: {
      type: Object
    }
  },
  setup(properties) {
    const {
      evolutionChartStructure,
      getGlobalData,
      getGlobalDataVariations,
      getSerieItemStructure,
      getNormsStructure
    } = useChart();
    const { isImperial } = useUnit().currentUnit();

    const title = 'commons.standards.speed';

    const speed = computed(() => getGlobalData(properties.speed, true));

    const dataVariations = computed(() => getGlobalDataVariations(speed.value.global));

    const series = computed(() => {
      let data = [
        getSerieItemStructure(
          'speed',
          `${i18n.t('commons.standards.speed')}`,
          speed.value.global,
          EThemeColor.Chart2
        ),
        getSerieItemStructure('speed', undefined, speed.value.ranges, EThemeColor.Chart2, true)
      ];
      // Have norms
      if (properties.norms)
        data.push(getNormsStructure('speed_norms', properties.norms as { min: number[]; max: number[] }));

      return data;
    });

    const chartOptions = computed(() => {
      return {
        ...evolutionChartStructure(
          title,
          properties.manifest,
          isImperial.value ? EUnitKey.MilePerHour : EUnitKey.KilometerPerHour,
          1
        ),
        series: series.value
      };
    });

    return {
      title,
      dataVariations,
      chartOptions
    };
  }
});
