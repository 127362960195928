
















// Import vendors ----------------------------------------------------------------------------------
import { defineComponent, computed } from '@vue/composition-api';
// Import components -------------------------------------------------------------------------------
import ChartContainer from '@/components/charts/ChartContainer.vue';
import ChartStandardGaugeSlider from '@/components/charts/standard/ChartStandardGaugeSlider.vue';
// Import helpers ----------------------------------------------------------------------------------
import { getUnit, EValueTypeUnit } from '@/helpers/helped-chart-functions.helper';
// Import utils ------------------------------------------------------------------------------------
import { useUnit } from '@/utils/unit.utils';
// -------------------------------------------------------------------------------------------------

export default defineComponent({
  name: 'ChartRunningWidgetSpeed',
  components: {
    ChartContainer,
    ChartStandardGaugeSlider
  },
  props: {
    aggregates: {
      type: Object,
      required: true
    },
    norms: {
      type: Object,
      required: true
    },
    displayMetrics: {
      type: Object,
      required: true
    },
    simple: {
      type: Boolean
    }
  },
  setup(properties) {
    const { currentUnit } = useUnit();

    const speedUnit = computed(() => getUnit(EValueTypeUnit.Speed));
    const valid = computed(() => properties.displayMetrics.aggregates?.running_speed);

    return {
      currentUnit,
      valid,
      speedUnit
    };
  }
});
