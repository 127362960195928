

















// Import vendors ----------------------------------------------------------------------------------
import { defineComponent, ref, watch } from '@vue/composition-api';
// -------------------------------------------------------------------------------------------------

export default defineComponent({
  name: 'ExportationFrameComments',
  props: {
    height: {
      type: Number,
      required: false,
      default: 300
    },
    vertical: {
      type: Boolean,
      default: false
    }
  },
  setup(_, { emit }) {
    const comments = ref('');
    const actions = ref('');

    watch(comments, () => {
      emit('setComments', comments.value);
    });

    watch(actions, () => {
      emit('setActions', actions.value);
    });

    return {
      comments,
      actions
    };
  }
});
