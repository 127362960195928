












// Import vendors ----------------------------------------------------------------------------------
import { defineComponent, ref, computed, Ref } from '@vue/composition-api';
// Import components -------------------------------------------------------------------------------
import ChartEvolutionContainer from '@/components/charts/ChartEvolutionContainer.vue';
// Import plugins ----------------------------------------------------------------------------------
import i18n from '@/plugins/i18n';
// Import utils - ----------------------------------------------------------------------------------
import { useChart, EThemeColor } from '@/utils/charts.utils';
import { EUnitKey } from '@/utils/parameter-units.utils';
// Import types ------------------------------------------------------------------------------------
import { TDataValue } from '@/components/patient/PatientEvolution.vue';
// -------------------------------------------------------------------------------------------------

export enum EPhase {
  Taligrade = 'taligrade',
  Plantigrade = 'plantigrade',
  Digitigrade = 'digitigrade'
}
export type TPhase = {
  [EPhase.Taligrade]: TDataValue;
  [EPhase.Plantigrade]: TDataValue;
  [EPhase.Digitigrade]: TDataValue;
};

export default defineComponent({
  name: 'ChartEvolutionWalkingPhases',
  components: {
    ChartEvolutionContainer
  },
  props: {
    manifest: {
      type: Array,
      required: true
    },
    taligrade: {
      type: Object
    },
    proportionTaligrade: {
      type: Object
    },
    plantigrade: {
      type: Object
    },
    proportionPlantigrade: {
      type: Object
    },
    digitigrade: {
      type: Object
    },
    proportionDigitigrade: {
      type: Object
    },
    relative: {
      type: Boolean,
      required: true
    }
  },
  setup(properties) {
    const mode: Ref<'abs' | 'rel'> = computed(() => (properties.relative ? 'rel' : 'abs'));

    const currentPhase: Ref<EPhase> = ref(EPhase.Taligrade);
    const { evolutionChartStructure, getSideData, getSideDataVariations, getSerieItemStructure } = useChart();

    const phases: Ref<TPhase> = computed(() => {
      return {
        [EPhase.Taligrade]: getSideData(
          properties[properties.relative ? 'proportionTaligrade' : 'taligrade']
        ),
        [EPhase.Plantigrade]: getSideData(
          properties[properties.relative ? 'proportionPlantigrade' : 'plantigrade']
        ),
        [EPhase.Digitigrade]: getSideData(
          properties[properties.relative ? 'proportionDigitigrade' : 'digitigrade']
        )
      };
    });

    const dataVariations = computed(() =>
      getSideDataVariations({
        left: phases.value[currentPhase.value].left.values,
        right: phases.value[currentPhase.value].right.values
      })
    );

    const series = computed(() => {
      return [
        // Taligrade
        // Left
        getSerieItemStructure(
          'taligrade_left',
          `${i18n.t('commons.standards.taligrade')} (${(i18n.t('commons.standards.left') as string).slice(
            0,
            1
          )})`,
          phases.value.taligrade.left.values,
          EThemeColor.Chart1
        ),
        getSerieItemStructure(
          'taligrade_left',
          undefined,
          phases.value.taligrade.left.ranges,
          EThemeColor.Chart1,
          true
        ),
        // Right
        getSerieItemStructure(
          'taligrade_right',
          `${i18n.t('commons.standards.taligrade')} (${(i18n.t('commons.standards.right') as string).slice(
            0,
            1
          )})`,
          phases.value.taligrade.right.values,
          EThemeColor.Chart2
        ),
        getSerieItemStructure(
          'taligrade_right',
          undefined,
          phases.value.taligrade.right.ranges,
          EThemeColor.Chart2,
          true
        ),
        // Plantigrade
        // Left
        getSerieItemStructure(
          'plantigrade_left',
          `${i18n.t('commons.standards.plantigrade')} (${(i18n.t('commons.standards.left') as string).slice(
            0,
            1
          )})`,
          phases.value.plantigrade.left.values,
          EThemeColor.Chart3,
          false,
          {
            visible: false
          }
        ),
        getSerieItemStructure(
          'plantigrade_left',
          undefined,
          phases.value.plantigrade.left.ranges,
          EThemeColor.Chart3,
          true
        ),
        // Right
        getSerieItemStructure(
          'plantigrade_right',
          `${i18n.t('commons.standards.plantigrade')} (${(i18n.t('commons.standards.right') as string).slice(
            0,
            1
          )})`,
          phases.value.plantigrade.right.values,
          EThemeColor.Chart4,
          false,
          {
            visible: false
          }
        ),
        getSerieItemStructure(
          'plantigrade_right',
          undefined,
          phases.value.plantigrade.right.ranges,
          EThemeColor.Chart4,
          true
        ),
        // Digitigrade
        // Left
        getSerieItemStructure(
          'digitigrade_left',
          `${i18n.t('commons.standards.digitigrade')} (${(i18n.t('commons.standards.left') as string).slice(
            0,
            1
          )})`,
          phases.value.digitigrade.left.values,
          EThemeColor.Chart5,
          false,
          {
            visible: false
          }
        ),
        getSerieItemStructure(
          'digitigrade_left',
          undefined,
          phases.value.digitigrade.left.ranges,
          EThemeColor.Chart5,
          true
        ),
        // Right
        getSerieItemStructure(
          'digitigrade_right',
          `${i18n.t('commons.standards.digitigrade')} (${(i18n.t('commons.standards.right') as string).slice(
            0,
            1
          )})`,
          phases.value.digitigrade.right.values,
          EThemeColor.Chart6,
          false,
          {
            visible: false
          }
        ),
        getSerieItemStructure(
          'digitigrade_right',
          undefined,
          phases.value.digitigrade.right.ranges,
          EThemeColor.Chart6,
          true
        )
      ];
    });

    function getLegendGroup(id: string) {
      if (id.includes('taligrade')) currentPhase.value = EPhase.Taligrade;
      if (id.includes('plantigrade')) currentPhase.value = EPhase.Plantigrade;
      if (id.includes('digitigrade')) currentPhase.value = EPhase.Digitigrade;
    }

    const chartOptions = computed(() => {
      return {
        ...evolutionChartStructure(
          { text: 'commons.standards.contact-phase', plural: 2 },
          properties.manifest,
          properties.relative ? EUnitKey.Percent : EUnitKey.Millisecond,
          0,
          {
            groups: [
              ['taligrade_left', 'taligrade_right', 'taligrade_left_range', 'taligrade_right_range'],
              ['plantigrade_left', 'plantigrade_right', 'plantigrade_left_range', 'plantigrade_right_range'],
              ['digitigrade_left', 'digitigrade_right', 'digitigrade_left_range', 'digitigrade_right_range']
            ],
            getLegendGroup
          }
        ),
        series: series.value
      };
    });

    return {
      dataVariations,
      chartOptions
    };
  }
});
