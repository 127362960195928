


















// Import vendors ----------------------------------------------------------------------------------
import { computed, defineComponent } from '@vue/composition-api';
// Import utils ------------------------------------------------------------------------------------
import { useCDN } from '@/utils/cdn.utils';
// -------------------------------------------------------------------------------------------------

export default defineComponent({
  name: 'ExportationViewerPage',
  props: {
    page: [Number, String],
    total: [Number, String]
  },
  setup() {
    const { cdn } = useCDN();

    const type = 'page';
    const width = computed(() => {
      const dpi = document.getElementById('export-dpi-sensor')!.clientWidth;
      return ((210 - 14 * 2) * dpi) / 25.4 + 'px';
    });

    return {
      // Values
      type,
      width,
      // Methods
      cdn
    };
  }
});
