<template>
  <div class="chart">
    <div v-if="!hideTitle" class="chart__title">
      {{ $t('commons.standards.propulsion-rate') }}
    </div>
    <div v-if="analysisDate" class="chart__date">{{ $moment(analysisDate).format('LLL') }}</div>
    <div class="chart__content">
      <div class="chart">
        <ChartWalkingPropulsionRatio :values="aggregates.walking_propulsion_ratio_corrected" />
      </div>
    </div>
  </div>
</template>

<script>
// Import vendors ----------------------------------------------------------------------------------
import { defineComponent } from '@vue/composition-api';
// Import components -------------------------------------------------------------------------------
import ChartWalkingPropulsionRatio from '@/components/charts/walking/ChartWalkingPropulsionRatio';
// -------------------------------------------------------------------------------------------------

export default defineComponent({
  name: 'ExportationFrameWalkingChartPropulsionRatio',
  components: {
    ChartWalkingPropulsionRatio
  },
  props: {
    aggregates: {
      type: Object,
      required: true
    },
    analysisDate: {
      type: String,
      required: false
    },
    hideTitle: {
      type: Boolean
    }
  }
});
</script>

<style lang="scss" scoped>
.chart {
  height: 340px;

  &__title {
    font-size: 1rem;
  }

  &__content {
    margin-top: 20px;

    .chart {
      position: relative;
    }
  }
}
</style>
