var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"mr-4"},[_c('RouterLink',{staticClass:"patient-navbar-item-title",class:{
      selected: _vm.isItemSelected
    },attrs:{"tag":"button","type":"button","to":{
      name: 'patient',
      params: {
        id: _vm.route.params.cuid
      },
      hash: _vm.item.hash
    }},on:{"click":function($event){return _vm.setPatientNavbarItem()}}},[_vm._v(" "+_vm._s(_vm.item.title)+" ")]),_c('div',{staticClass:"bottom-bar",class:{ selected: _vm.isItemSelected }})],1)}
var staticRenderFns = []

export { render, staticRenderFns }