<template>
  <div class="exportation-header">
    <!--region Doctor-->
    <ExportationFrameDoctor v-bind="{ showEmail, showPhone, workspace }" />
    <!--endregion-->

    <!--region Patient-->
    <ExportationFramePatient v-bind="{ patient, analysis, comparisonMode }" />
    <!--endregion-->

    <VDivider class="divider ghost" />

    <!--region Title-->
    <ExportationFrameTitle :type="scenario" />
    <!--endregion-->

    <VDivider class="divider ghost" />
  </div>
</template>

<script>
import { defineComponent } from '@vue/composition-api';
// Import components -------------------------------------------------------------------------------
import ExportationFrameDoctor from '@/components/exportation/frames/ExportationFrameDoctor.vue';
import ExportationFramePatient from '@/components/exportation/frames/ExportationFramePatient.vue';
import ExportationFrameTitle from '@/components/exportation/frames/ExportationFrameTitle.vue';
// -------------------------------------------------------------------------------------------------

export default defineComponent({
  name: 'ExportationFrameHeader',
  components: {
    ExportationFrameDoctor,
    ExportationFramePatient,
    ExportationFrameTitle
  },
  props: {
    analysis: {
      type: Object,
      required: true
    },
    scenario: {
      type: String,
      required: true
    },
    patient: {
      type: Object,
      required: true
    },
    showEmail: {
      type: Boolean,
      required: true
    },
    showPhone: {
      type: Boolean,
      required: true
    },
    workspace: {
      type: Object,
      required: true
    },
    comparisonMode: {
      type: Boolean
    }
  }
});
</script>
