





































































// Import vendors ----------------------------------------------------------------------------------
import { defineComponent, ref, reactive, watch } from '@vue/composition-api';
// Import components -------------------------------------------------------------------------------
import ElementResultsSwitchRelative from '@/components/elements/ElementResultsSwitchRelative.vue';
// Import helpers ----------------------------------------------------------------------------------
import { staticValues } from '@/helpers/static-values.helper';
// Import types ------------------------------------------------------------------------------------
import { AnalysisType } from '@/plugins/podocore/repositories/analysis.repository';
// -------------------------------------------------------------------------------------------------

enum ScenarioKey {
  WalkingEmbedded = 'walking_embedded',
  RunningEmbedded = 'running_embedded',
  JumpingEmbedded = 'jumping_embedded'
}
export enum EAnalysisName {
  Walking = 'walk',
  Running = 'run',
  Jumping = 'jumping'
}
export enum EAnalysisType {
  Walking = 'walking',
  Running = 'running',
  Jumping = 'jumping'
}
export enum EPathology {
  Default = 'default',
  Neurological = 'neurological',
  Traumatology = 'traumatology',
  Reathleticism = 're-athleticism',
  Custom = 'custom'
}
export type TAnalysisNames = {
  [AnalysisType.walking]: EAnalysisName.Walking;
  [AnalysisType.running]: EAnalysisName.Running;
  [AnalysisType.jumping]: EAnalysisName.Jumping;
};
export type TAnalysisType = {
  [AnalysisType.walking]: EAnalysisType.Walking;
  [AnalysisType.running]: EAnalysisType.Running;
  [AnalysisType.jumping]: EAnalysisType.Jumping;
};

export default defineComponent({
  name: 'PatientEvolutionHeader',
  components: {
    ElementResultsSwitchRelative
  },
  props: {
    degradedAnalysesNumber: {
      type: Number,
      required: true
    },
    hideDegradedAnalyses: {
      type: Boolean,
      required: true
    }
  },
  setup(properties, { emit }) {
    const { scenarioKeyToAnalysisType } = staticValues();

    const scenarioKey = ref(ScenarioKey.WalkingEmbedded);
    const relative = ref(false);

    const nameByAnalysisType: TAnalysisNames = reactive({
      [AnalysisType.walking]: EAnalysisName.Walking,
      [AnalysisType.running]: EAnalysisName.Running,
      [AnalysisType.jumping]: EAnalysisName.Jumping
    });
    const refactoredAnalysisType: TAnalysisType = reactive({
      [AnalysisType.walking]: EAnalysisType.Walking,
      [AnalysisType.running]: EAnalysisType.Running,
      [AnalysisType.jumping]: EAnalysisType.Jumping
    });

    type TJumpingModeType = 'CMJ' | 'triple-hop' | 'single-hop' | 'side-hop';

    type TJumpingModeKey =
      | 'jumping_CMJ_raw'
      | 'jumping_triple_hop_raw'
      | 'jumping_single_hop_raw'
      | 'jumping_side_hop_raw';

    type TJumpingMode = {
      type: TJumpingModeType;
      key: TJumpingModeKey;
    };

    const jumpingModes: TJumpingMode[] = [
      {
        type: 'CMJ',
        key: 'jumping_CMJ_raw'
      },
      {
        type: 'triple-hop',
        key: 'jumping_triple_hop_raw'
      },
      {
        type: 'single-hop',
        key: 'jumping_single_hop_raw'
      },
      {
        type: 'side-hop',
        key: 'jumping_side_hop_raw'
      }
    ];

    const getJumpingModeType = (key: TJumpingModeKey) => {
      return jumpingModes.find((mode) => mode.key === key)?.type;
    };

    const jumpingModeKey = ref<TJumpingModeKey>();

    watch(scenarioKey, (_scenarioKey) => {
      if (!_scenarioKey.startsWith('jumping')) emit('setScenarioKey', _scenarioKey);
    });

    watch(
      jumpingModeKey,
      (_jumpingKey) => {
        if (_jumpingKey) {
          scenarioKey.value = ScenarioKey.JumpingEmbedded;
          if (scenarioKey.value === ScenarioKey.JumpingEmbedded) emit('setScenarioKey', _jumpingKey);
        }
      },
      { deep: true }
    );

    watch(relative, (_relative) => {
      emit('setRelativeMode', _relative);
    });

    function toggleHideDegradedAnalyses() {
      emit('toggleHideDegradedAnalyses', properties.hideDegradedAnalyses);
    }

    const onWalkRunButtonClick = (scenario: ScenarioKey) => {
      scenarioKey.value = scenario;
      jumpingModeKey.value = undefined;
    };

    return {
      // Values
      scenarioKey,
      scenarioKeyToAnalysisType,
      relative,
      jumpingModes,
      jumpingModeKey,
      // Helpers
      nameByAnalysisType,
      refactoredAnalysisType,
      // Enums
      ScenarioKey,
      // Methods
      getJumpingModeType,
      onWalkRunButtonClick,
      toggleHideDegradedAnalyses
    };
  }
});
