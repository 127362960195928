











































































// Import vendors ----------------------------------------------------------------------------------
import { defineComponent, ref, computed, watch, onMounted, onBeforeUnmount } from '@vue/composition-api';
import { cloneDeep, get, isArray, isPlainObject, mergeWith } from 'lodash';
import { useLocalStorage } from '@vueuse/core';
// Import components -------------------------------------------------------------------------------
import PatientEvolutionHeader from '@/components/patient/PatientEvolutionHeader.vue';
import ChartEvolutionGroupsWalking from '@/components/charts/evolution/groups/ChartEvolutionGroupsWalking.vue';
import ChartEvolutionGroupsRunning from '@/components/charts/evolution/groups/ChartEvolutionGroupsRunning.vue';
import ChartEvolutionGroupsRehabCMJ from '@/components/charts/evolution/groups/ChartEvolutionGroupsRehabCMJ.vue';
import ChartEvolutionGroupsRehabTripleHop from '@/components/charts/evolution/groups/ChartEvolutionGroupsRehabTripleHop.vue';
import ChartEvolutionGroupsRehabSideHop from '@/components/charts/evolution/groups/ChartEvolutionGroupsRehabSideHop.vue';
import ChartEvolutionGroupsRehabSingleHop from '@/components/charts/evolution/groups/ChartEvolutionGroupsRehabSingleHop.vue';
import AlertError from '@/components/alerts/AlertError.vue';
// Import plugins ----------------------------------------------------------------------------------
import { usePodocoreModule } from '@/plugins/podocore';
// Import utils ------------------------------------------------------------------------------------
import { usePatient } from '@/utils/patient.utils';
// Import config -----------------------------------------------------------------------------------
import { apiConfig } from '@/config/api.config';
// -------------------------------------------------------------------------------------------------

export type TDataStandard = {
  value: TDataValue;
};

export type TDataValue = {
  left: TDataSide;
  right: TDataSide;
};

export type TDataSide = {
  values: number[];
  ranges: number[][];
};

enum EScenarioKey {
  WalkingEmbedded = 'walking_embedded',
  RunningEmbedded = 'running_embedded'
}

export default defineComponent({
  name: 'PatientEvolution',
  components: {
    PatientEvolutionHeader,
    ChartEvolutionGroupsWalking,
    ChartEvolutionGroupsRunning,
    AlertError,
    ChartEvolutionGroupsRehabCMJ,
    ChartEvolutionGroupsRehabTripleHop,
    ChartEvolutionGroupsRehabSideHop,
    ChartEvolutionGroupsRehabSingleHop
  },
  setup() {
    const { data: patient } = usePatient();

    const scenarioKey = ref(EScenarioKey.WalkingEmbedded);
    const relative = ref(false);
    const hideDegradedAnalyses = useLocalStorage('evolution:hide-degraded-analyses', true, {
      listenToStorageChanges: true
    });

    const requestModule = usePodocoreModule('request');

    const evolutionRequest: any = ref(null);

    const evolutionData = computed(() => {
      if (evolutionRequest.value?.data) {
        // Clone to avoid mutating the original data
        const clonedData = cloneDeep(evolutionRequest.value.data);

        let baseEvolution = clonedData[0];

        if (clonedData.length > 1) {
          const subparts = clonedData.map(({ analysisManifests, data }: any) => ({
            analysisManifests,
            data
          }));

          const subpartsMerge = mergeWith(subparts[0], ...subparts.slice(1), mergeWithProcessor);

          const fragmentExtractor = (source: any, index: number): any => {
            return Object.entries(source).reduce((accumulator, [key, value]: any) => {
              if (isPlainObject(value)) {
                (accumulator as any)[String(key)] = fragmentExtractor(value, index);
              } else {
                (accumulator as any)[String(key)] = [value[Number(index)]];
              }

              return accumulator;
            }, {} as any);
          };

          const fragments = subpartsMerge.analysisManifests.reduce(
            (accumulator: any, analysisManifest: any, index: number) => {
              accumulator.push({
                analysisManifest,
                data: fragmentExtractor(subpartsMerge.data, index)
              });
              return accumulator;
            },
            []
          );

          const sortedFragments = fragments.sort((a: any, b: any) => {
            return (
              new Date(a.analysisManifest.createdAt).getTime() -
              new Date(b.analysisManifest.createdAt).getTime()
            );
          });

          const condensateFragments = sortedFragments.reduce(
            (accumulator: any, { analysisManifest, data }: any, index: number) => {
              accumulator.analysisManifests.push(analysisManifest);
              if (index === 0) accumulator.data = data;
              else mergeWith(accumulator.data, data, mergeWithProcessor);
              return accumulator;
            },
            {
              analysisManifests: [],
              data: {} as any
            } as any
          );

          return Object.assign({}, baseEvolution, condensateFragments);
        }

        return baseEvolution;
      }

      return undefined;
    });

    const degradedAnalysesIndexes = computed(() => {
      if (!evolutionData.value) return [];
      const result = [];
      for (let index = 0; index < evolutionData.value.analysisManifests.length; index++) {
        if (evolutionData.value.analysisManifests[Number(index)].analysis_validity !== 2) result.push(index);
      }
      return result;
    });

    const sanitizedEvolutionData = computed(() => {
      if (!evolutionData.value) return undefined;

      if (hideDegradedAnalyses.value) {
        const clonedBaseEvolution = cloneDeep(evolutionData.value);

        const removeNested = (data: any, index: number) => {
          if (isPlainObject(data)) {
            Object.keys(data).forEach((key: string) => {
              removeNested(data[String(key)], index);
            });
          } else if (isArray(data)) {
            data.splice(index, 1);
          }
        };

        let deletionCounter = 0;

        degradedAnalysesIndexes.value.forEach((index) => {
          removeNested(clonedBaseEvolution.data, index - deletionCounter);
          clonedBaseEvolution.analysisManifests.splice(index - deletionCounter, 1);
          deletionCounter++;
        });

        return clonedBaseEvolution;
      } else {
        return evolutionData.value;
      }
    });

    function mergeWithProcessor<T, S>(objectValue: T, sourceValue: S) {
      if (isArray(objectValue)) {
        return objectValue.concat(sourceValue);
      }
    }

    function launchEvolutionRequest(scenarioKey: string) {
      evolutionRequest.value = requestModule.useAuthenticatedRequest(`${apiConfig.default}/evolutions`, {
        axios: {
          params: {
            patientCuid: patient.value?.cuid,
            scenarioKey: scenarioKey.startsWith('jumping') ? scenarioKey : scenarioKey.split('_')[0],
            scenarioKeyIsRegex: true
          }
        }
      });
      evolutionRequest.value?.request();
    }

    const evolutionIsNotFound = computed(() => {
      return get(evolutionRequest.value, 'error.response.data.message') === 'evolution not found';
    });

    function setScenarioKey(_scenarioKey: EScenarioKey) {
      scenarioKey.value = _scenarioKey;
    }
    function setRelativeMode(_relative: boolean) {
      relative.value = _relative;
    }

    function toggleHideDegradedAnalyses(value: boolean) {
      hideDegradedAnalyses.value = !value;
    }

    watch(scenarioKey, (key) => {
      launchEvolutionRequest(key);
    });

    onMounted(() => {
      launchEvolutionRequest(scenarioKey.value);
    });

    onBeforeUnmount(() => {
      evolutionRequest.value?.cancel();
    });

    return {
      // Requests
      evolutionRequest,
      // Values
      scenarioKey,
      evolutionData,
      sanitizedEvolutionData,
      relative,
      degradedAnalysesIndexes,
      hideDegradedAnalyses,
      // Flags
      evolutionIsNotFound,
      // Banner event
      setScenarioKey,
      setRelativeMode,
      toggleHideDegradedAnalyses
    };
  }
});
