














// Import vendors ----------------------------------------------------------------------------------
import { defineComponent } from '@vue/composition-api';
// Import components -------------------------------------------------------------------------------
import ChartWalkingClearanceSteppage from '@/components/charts/walking/ChartWalkingClearanceSteppage.vue';
// -------------------------------------------------------------------------------------------------

export default defineComponent({
  name: 'ExportationFrameWalkingChartClearanceSteppage',
  components: {
    ChartWalkingClearanceSteppage
  },
  props: {
    aggregates: {
      type: Object,
      required: true
    },
    analysisDate: {
      type: String,
      required: false
    },
    hideTitle: {
      type: Boolean
    }
  }
});
