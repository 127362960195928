










// Import vendors ----------------------------------------------------------------------------------
import { defineComponent, ref, computed, watchEffect, Ref } from '@vue/composition-api';
// Import plugins ----------------------------------------------------------------------------------
import { DrawSVG } from '@/plugins/charts/strike-pattern/strike-pattern-running';
// -------------------------------------------------------------------------------------------------

export default defineComponent({
  name: 'ChartRunningStrikePatternAdvanced',
  props: {
    aggregates: {
      type: Object,
      required: true
    }
  },
  setup(properties) {
    const rCanvas: Ref<HTMLCanvasElement | null> = ref(null);
    const rLeftFootCanvas: Ref<HTMLCanvasElement | null> = ref(null);
    const rRightFootCanvas: Ref<HTMLCanvasElement | null> = ref(null);

    const phases = computed(() => {
      return {
        leftFoot: {
          angle: properties.aggregates.running_plantar_flexion_angle_foot_in.left_foot.avg,
          impact: properties.aggregates.running_impact_force.left_foot.avg,
          angleType: properties.aggregates.running_strike_pattern.left_foot.avg
        },
        rightFoot: {
          angle: properties.aggregates.running_plantar_flexion_angle_foot_in.right_foot.avg,
          impact: properties.aggregates.running_impact_force.right_foot.avg,
          angleType: properties.aggregates.running_strike_pattern.right_foot.avg
        }
      };
    });

    function draw() {
      const shoesCanvas = {
        leftFoot: rLeftFootCanvas.value as HTMLCanvasElement,
        rightFoot: rRightFootCanvas.value as HTMLCanvasElement
      };
      if (rCanvas.value) {
        const graphCanvas = new DrawSVG(rCanvas.value as HTMLCanvasElement, phases.value, shoesCanvas);
        requestAnimationFrame(() => {
          graphCanvas!.draw();
        });
      }
    }

    watchEffect(() => {
      draw();
    });

    return {
      rCanvas,
      rLeftFootCanvas,
      rRightFootCanvas
    };
  }
});
