
































































































// Import vendors ----------------------------------------------------------------------------------
import { defineComponent, computed } from '@vue/composition-api';
// Import components -------------------------------------------------------------------------------
import ChartEvolutionRehabSide from '@/components/charts/evolution/rehab/ChartEvolutionRehabSide.vue';
// -------------------------------------------------------------------------------------------------

export default defineComponent({
  name: 'ChartEvolutionGroupsRehabSideHop',
  components: {
    ChartEvolutionRehabSide
  },
  props: {
    values: {
      type: Object,
      required: true
    },
    relative: {
      type: Boolean,
      required: true
    }
  },
  setup(properties) {
    const aggregates = computed(() => properties.values.data.aggregates);
    const manifest = computed(() => properties.values.analysisManifests);

    return {
      aggregates,
      manifest
    };
  }
});
