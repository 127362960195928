












// Import vendors ----------------------------------------------------------------------------------
import { defineComponent } from '@vue/composition-api';
// Import components -------------------------------------------------------------------------------
import ChartRunningStrikePatternAdvanced from '@/components/charts/running/ChartRunningStrikePatternAdvanced.vue';
// -------------------------------------------------------------------------------------------------

export default defineComponent({
  name: 'ExportationFrameRunningChartStrikePattern',
  components: {
    ChartRunningStrikePatternAdvanced
  },
  props: {
    aggregates: {
      type: Object,
      required: true
    },
    analysisDate: {
      type: String
    },
    hideTitle: {
      type: Boolean
    }
  }
});
