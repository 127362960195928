











// Import vendors ----------------------------------------------------------------------------------
import { defineComponent, computed } from '@vue/composition-api';
// Import plugins ----------------------------------------------------------------------------------
import i18n from '@/plugins/i18n';
// -------------------------------------------------------------------------------------------------

export default defineComponent({
  name: 'ExportationFrameTitle',
  props: {
    type: {
      type: String,
      required: true
    }
  },
  setup(properpeties) {
    const title = computed(() => {
      if (properpeties.type.startsWith('walking')) return i18n.tc('commons.sentences.results-walk-analysis');
      if (properpeties.type.startsWith('running')) return i18n.tc('commons.sentences.results-run-analysis');
      if (properpeties.type.startsWith('jumping')) return i18n.tc('commons.sentences.results-jump-analysis');
      return 'N/A';
    });

    return {
      title
    };
  }
});
