
























// Import vendors ----------------------------------------------------------------------------------
import { defineComponent, computed, nextTick } from '@vue/composition-api';
// Import utils ------------------------------------------------------------------------------------
import { useRoute } from '@/utils/router.utils';
// Import type -------------------------------------------------------------------------------------
import type { PropType } from '@vue/composition-api';
import type { NavItem } from '@/components/patient/PatientNavbar.vue';
// -------------------------------------------------------------------------------------------------

export default defineComponent({
  name: 'PatientNavbarItem',
  props: {
    item: {
      type: Object as PropType<NavItem>,
      required: true
    }
  },
  setup(properties, { root }) {
    const route = useRoute();
    const isPatientFilePage = computed(() => {
      return (
        !root.$route.path.includes('result') &&
        !root.$route.path.includes('evolution') &&
        !root.$route.path.includes('comparison') &&
        !root.$route.path.includes('medias')
      );
    });

    const isItemSelected = computed(
      () => isPatientFilePage.value && route.hash.value === properties.item.hash
    );

    return {
      // Values
      route,
      isItemSelected
    };
  }
});
