








// Import vendors ----------------------------------------------------------------------------------
import { defineComponent, ref } from '@vue/composition-api';
// Import components -------------------------------------------------------------------------------
import ChartStandardPronationSupinationAngles from '@/components/charts/standard/ChartStandardPronationSupinationAngles.vue';
// -------------------------------------------------------------------------------------------------

export default defineComponent({
  name: 'ChartRunningGroupStepsAngles',
  components: {
    ChartStandardPronationSupinationAngles
  },
  props: {
    values: {
      type: Object,
      required: true
    }
  },
  setup() {
    const angles = ref([{ heelStrike: 'foot_in' }, { flatFoot: 'mid' }, { toesOff: 'foot_out' }]);

    return { angles };
  }
});
