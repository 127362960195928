<template>
  <div class="document">
    <!--region PAGE 1-->
    <ExportationViewerPage :total="pageTotal" page="1">
      <ExportationFrameHeader class="top-margin-lg" v-bind="{ ...headerInformations }" />

      <ExportationFrameRunningChartBanner v-bind="{ aggregates, displayMetrics, norms }" class="top-margin" />
      <ExportationFrameRunningChartSpeedCadence class="top-margin" v-bind="{ segments }" />
    </ExportationViewerPage>
    <!--endregion-->

    <!--region PAGE 2-->
    <ExportationViewerPage :total="pageTotal" page="2">
      <ExportationFrameRunningChartRunProfile v-bind="{ aggregates, displayMetrics, norms }" />

      <ExportationFrameRunningChartPronationAngles
        v-bind="{ aggregates, displayMetrics, norms, advancedMode }"
        class="top-margin-lg bottom-margin-lg"
        :height="advancedMode ? 350 : 120"
      />
      <ExportationFrameRunningChartStrikePattern
        v-if="advancedMode"
        v-bind="{ aggregates, displayMetrics, norms, advancedMode }"
        class="top-margin-lg"
      />
      <ExportationFrameComments
        v-if="!advancedMode"
        @setComments="setComments($event)"
        @setActions="setActions($event)"
        class="top-margin-lg"
      />
    </ExportationViewerPage>
    <!--Comments-->
    <ExportationViewerPage v-if="advancedMode" :total="pageTotal" page="3">
      <ExportationFrameComments
        @setComments="setComments($event)"
        @setActions="setActions($event)"
        :vertical="advancedMode"
        :height="270"
      />
    </ExportationViewerPage>
    <!--endregion-->

    <!--endregion-->
  </div>
</template>

<script>
import { defineComponent, computed } from '@vue/composition-api';
// Import components -------------------------------------------------------------------------------
import ExportationViewerPage from '@/components/exportation/ExportationViewerPage.vue';
import ExportationFrameHeader from '@/components/exportation/frames/ExportationFrameHeader.vue';
import ExportationFrameComments from '@/components/exportation/frames/ExportationFrameComments.vue';
// Charts
import ExportationFrameRunningChartBanner from '@/components/exportation/frames/running/charts/ExportationFrameRunningChartBanner.vue';
import ExportationFrameRunningChartSpeedCadence from '@/components/exportation/frames/running/charts/ExportationFrameRunningChartSpeedCadence.vue';
import ExportationFrameRunningChartRunProfile from '@/components/exportation/frames/running/charts/ExportationFrameRunningChartRunProfile.vue';
import ExportationFrameRunningChartPronationAngles from '@/components/exportation/frames/running/charts/ExportationFrameRunningChartPronationAngles.vue';
import ExportationFrameRunningChartStrikePattern from '@/components/exportation/frames/running/charts/ExportationFrameRunningChartStrikePattern.vue';

export default defineComponent({
  name: 'ExportationFrameRunning',
  components: {
    ExportationViewerPage,
    ExportationFrameHeader,
    ExportationFrameComments,
    // Charts
    ExportationFrameRunningChartBanner,
    ExportationFrameRunningChartSpeedCadence,
    ExportationFrameRunningChartRunProfile,
    ExportationFrameRunningChartPronationAngles,
    ExportationFrameRunningChartStrikePattern
  },
  props: {
    analysis: {
      type: Object,
      required: true
    },
    advancedMode: {
      type: Boolean,
      required: true
    },
    pageTotal: {
      type: Number,
      required: true,
      default: 0
    },
    headerInformations: {
      type: Object,
      required: true
    }
  },
  setup(properties) {
    const aggregates = computed(() => properties.analysis.metrics.aggregates);
    const segments = computed(() => properties.analysis.metrics.segment);
    const displayMetrics = computed(() => properties.analysis.display_metrics);
    const norms = computed(() => properties.analysis.metrics.norms);

    return {
      aggregates,
      segments,
      displayMetrics,
      norms
    };
  }
});
</script>

<style lang="scss" scoped>
.top-margin {
  margin-top: 30px;
  &-sm {
    margin-top: 20px;
  }
  &-lg {
    margin-top: 50px;
  }
}
</style>
