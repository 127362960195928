import i18n from '@/plugins/i18n';
import { DrawShoes } from '@/plugins/charts/strike-pattern/angles-strike-shoes-running';

export type TPhases = {
  [key: string]: any;
  leftFoot: TPhase;
  rightFoot: TPhase;
};
export type TPhase = {
  [key: string]: any;
  angle: number;
  impact: number;
  angleType: string;
};

export class DrawSVG {
  // Val
  private readonly canvas: HTMLCanvasElement;
  private readonly shoesCanvas: any;
  private readonly ctx: CanvasRenderingContext2D;

  private readonly phases: TPhases = {
    leftFoot: {
      angle: 0,
      impact: 0,
      angleType: ''
    },
    rightFoot: {
      angle: 0,
      impact: 0,
      angleType: ''
    }
  };

  private readonly pos: any = {
    chartStructure: {
      startY: 0,
      endY: 0
    },
    middleX: 0,
    xPad: {
      leftFoot: 0,
      rightFoot: 0
    },
    x: {
      leftFoot: {
        rearfoot_strike: 0,
        midfoot_strike: 0,
        forefoot_strike: 0,
        valueText: 0
      },
      rightFoot: {
        rearfoot_strike: 0,
        midfoot_strike: 0,
        forefoot_strike: 0,
        valueText: 0
      }
    }
  };

  private readonly angleTranslations: any = {
    rearfoot_strike: 'commons.standards.heel',
    midfoot_strike: 'commons.standards.midfoot',
    forefoot_strike: 'commons.standards.foreFoot'
  };

  private leftFootGenerator: any;
  private rightFootGenerator: any;

  constructor(canvas: HTMLCanvasElement, phases: TPhases, shoesCanvas: any) {
    this.canvas = canvas;
    this.phases = phases;
    this.shoesCanvas = shoesCanvas;
    this.ctx = canvas.getContext('2d')!;
    // Structure data
    this.pos.chartStructure.startY = this.canvas.height * 0.15; // get 15%
    this.pos.chartStructure.endY = this.canvas.height * 0.7; // get 70%
    this.pos.middleX = this.canvas.width / 2;
    // Value positions
    this.pos.x.leftFoot.rearfoot_strike = this.pos.middleX - this.pos.middleX / 2;
    this.pos.x.leftFoot.midfoot_strike =
      this.pos.x.leftFoot.rearfoot_strike + this.pos.x.leftFoot.rearfoot_strike / 2;
    this.pos.x.leftFoot.forefoot_strike = this.pos.x.leftFoot.rearfoot_strike * 2;
    this.pos.x.leftFoot.angleText = this.pos.middleX - this.pos.middleX / 3;

    this.pos.x.rightFoot.rearfoot_strike = this.pos.x.leftFoot.rearfoot_strike * 2;
    this.pos.x.rightFoot.midfoot_strike =
      this.pos.x.rightFoot.rearfoot_strike + this.pos.x.leftFoot.rearfoot_strike / 2;
    this.pos.x.rightFoot.forefoot_strike = this.pos.middleX + this.pos.middleX / 2;
    this.pos.x.rightFoot.angleText = this.pos.middleX + this.pos.middleX / 3;
    // Value positions
    this.pos.xPad.leftFoot = (this.canvas.width / 10) * -1;
    this.pos.xPad.rightFoot = this.canvas.width / 10;
  }

  async draw() {
    this.clean();
    // Draw
    this.shoeAngle();
    this.contentStructure();
    this.anglesValueText();
    this.impactForceValue();
  }

  shoeAngle() {
    // GLOBALS
    const startX: any = {
      leftFoot: (this.canvas.width / 10) * -1,
      rightFoot: this.canvas.width / 10
    };
    const angleHeight = 18;
    const line = {
      size: 70,
      width: 3,
      marginBottom: 1.5
    };
    const angles: any = {
      leftFoot: {
        x: Math.cos((angleHeight * Math.PI) / 180) * line.size * -1,
        y: Math.sin((angleHeight * Math.PI) / 180) * line.size
      },
      rightFoot: {
        x: Math.cos((angleHeight * Math.PI) / 180) * line.size,
        y: Math.sin((angleHeight * Math.PI) / 180) * line.size
      }
    };
    const posX: any = {
      leftFoot: (this.canvas.width / 10) * -1,
      rightFoot: this.canvas.width / 10
    };
    //
    this.ctx.fillStyle = 'rgba(63,145,222,0.1)';
    this.ctx.strokeStyle = '#144CD4';
    this.ctx.lineWidth = line.width;
    this.ctx.lineCap = 'round';

    // --- DRAW --- //

    // SHOE IMAGES
    this.leftFootGenerator = new DrawShoes(
      this.shoesCanvas.leftFoot,
      this.pos.x.leftFoot[this.phases.leftFoot.angleType] + posX.leftFoot,
      this.pos.chartStructure.endY,
      'leftFoot',
      this.phases.leftFoot.angleType
    );
    requestAnimationFrame(() => {
      this.leftFootGenerator.drawShoes();
    });

    // SHOE IMAGES
    this.rightFootGenerator = new DrawShoes(
      this.shoesCanvas.rightFoot,
      this.pos.x.rightFoot[this.phases.rightFoot.angleType] + posX.rightFoot,
      this.pos.chartStructure.endY,
      'rightFoot',
      this.phases.rightFoot.angleType
    );
    requestAnimationFrame(() => {
      this.rightFootGenerator.drawShoes();
    });

    const that = this;
    const changeDirection = function (side: string) {
      if (side === 'leftFoot' && that.phases[side].angleType === 'forefoot_strike') return -1;
      if (side === 'rightFoot' && that.phases[side].angleType === 'rearfoot_strike') return -1;
      return 1;
    };

    // ANGLES LINES
    for (const side of ['leftFoot', 'rightFoot']) {
      if (this.phases[side].angleType !== 'midfoot_strike') {
        this.ctx.fillStyle = '#F2F2F2';
        // Angle shape
        this.ctx.beginPath();
        this.ctx.moveTo(
          this.pos.x[side][this.phases[side].angleType] + startX[side],
          this.pos.chartStructure.endY - line.marginBottom
        );
        this.ctx.lineTo(
          this.pos.x[side][this.phases[side].angleType] +
            startX[side] -
            angles[side].x * changeDirection(side),
          this.pos.chartStructure.endY - angles[side].y - line.marginBottom
        );
        this.ctx.lineTo(
          this.pos.x[side][this.phases[side].angleType] +
            startX[side] -
            angles[side].x * changeDirection(side),
          this.pos.chartStructure.endY - line.marginBottom
        );
        this.ctx.fill();

        // Angle line
        this.ctx.strokeStyle = side === 'leftFoot' ? '#FFBD1A' : '#144CD4';
        this.ctx.beginPath();
        this.ctx.moveTo(
          this.pos.x[side][this.phases[side].angleType] + startX[side],
          this.pos.chartStructure.endY - line.marginBottom
        );
        this.ctx.lineTo(
          this.pos.x[side][this.phases[side].angleType] +
            startX[side] -
            angles[side].x * changeDirection(side),
          this.pos.chartStructure.endY - angles[side].y - line.marginBottom
        );
        this.ctx.stroke();
      }
    }
  }

  contentStructure() {
    // GLOBALS
    const letterSize = 15;
    //
    this.ctx.lineWidth = 2;
    this.ctx.strokeStyle = '#141517';
    this.ctx.font = '600 14px Montserrat';

    // --- DRAW --- //

    // Horizontal bar
    this.ctx.beginPath();
    this.ctx.moveTo(0, this.pos.chartStructure.endY);
    this.ctx.lineTo(this.canvas.width, this.pos.chartStructure.endY);
    this.ctx.stroke();
    // Vertical bar
    this.ctx.beginPath();
    this.ctx.moveTo(this.pos.middleX, this.pos.chartStructure.startY);
    this.ctx.lineTo(this.pos.middleX, this.pos.chartStructure.endY);
    this.ctx.stroke();

    // Letters side
    this.ctx.textAlign = 'right';
    this.ctx.fillStyle = '#a1681a';
    this.ctx.fillText(
      (i18n.t('commons.standards.left') as string).slice(0, 1),
      this.pos.middleX - letterSize,
      this.pos.chartStructure.startY + letterSize
    );
    this.ctx.textAlign = 'left';
    this.ctx.fillStyle = '#144CD4';
    this.ctx.fillText(
      (i18n.t('commons.standards.right') as string).slice(0, 1),
      this.pos.middleX + letterSize,
      this.pos.chartStructure.startY + letterSize
    );
  }

  anglesValueText() {
    // GLOBALS
    const letterSize = 15;
    const valuesBackground = {
      height: 25,
      width: 65,
      yPadding: 10
    };

    // --- DRAW --- //

    for (const side of ['leftFoot', 'rightFoot']) {
      this.ctx.font = '500 18px Montserrat';
      // Value title text
      this.ctx.textAlign = 'center';
      this.ctx.fillStyle = side === 'leftFoot' ? '#a1681a' : '#144CD4';
      const Ypad = this.phases[side].angleType === 'midfoot_strike' ? 15 : 0;
      this.ctx.fillText(
        i18n.t(this.angleTranslations[this.phases[side].angleType]) as string,
        this.pos.x[side].angleText + this.pos.xPad[side],
        letterSize + Ypad
      );

      this.ctx.font = '600 15px Montserrat';

      if (this.phases[side].angleType !== 'midfoot_strike') {
        // Background
        this.ctx.fillStyle = '#EBF4FB';
        this.ctx.beginPath();
        this.ctx.rect(
          this.pos.x[side].angleText + this.pos.xPad[side] - valuesBackground.width / 2,
          valuesBackground.yPadding + valuesBackground.height / 2,
          valuesBackground.width,
          valuesBackground.height
        );
        this.ctx.fill();

        // Angle value
        this.ctx.fillStyle = '#144CD4';
        this.ctx.fillText(
          `${this.phases[side].angle}°`,
          this.pos.x[side].angleText + this.pos.xPad[side],
          this.pos.chartStructure.startY + letterSize
        );
      }
    }
  }

  impactForceValue() {
    // GLOBALS
    const circle: any = {
      radius: 7
    };
    const arrow = {
      lineHeight: 20,
      head: {
        width: 12,
        height: 12
      }
    };
    const letterSize = 20;
    //
    this.ctx.lineWidth = 2;
    this.ctx.font = '600 18px Montserrat';

    // --- DRAW --- //

    for (const side of ['leftFoot', 'rightFoot']) {
      this.ctx.fillStyle = side === 'leftFoot' ? '#FFBD1A' : '#144CD4';
      this.ctx.strokeStyle = side === 'leftFoot' ? '#FFBD1A' : '#144CD4';
      // Arrow line
      this.ctx.beginPath();
      this.ctx.moveTo(
        this.pos.x[side][this.phases[side].angleType] + this.pos.xPad[side],
        this.pos.chartStructure.endY
      );
      this.ctx.lineTo(
        this.pos.x[side][this.phases[side].angleType] + this.pos.xPad[side],
        this.pos.chartStructure.endY + arrow.lineHeight
      );
      this.ctx.stroke();

      // Arrow head
      this.ctx.beginPath();
      this.ctx.moveTo(
        this.pos.x[side][this.phases[side].angleType] + this.pos.xPad[side] - arrow.head.width / 2,
        this.pos.chartStructure.endY + arrow.lineHeight
      );
      this.ctx.lineTo(
        this.pos.x[side][this.phases[side].angleType] + this.pos.xPad[side] + arrow.head.width / 2,
        this.pos.chartStructure.endY + arrow.lineHeight
      );
      this.ctx.lineTo(
        this.pos.x[side][this.phases[side].angleType] + this.pos.xPad[side],
        this.pos.chartStructure.endY + arrow.lineHeight + arrow.head.height
      );
      this.ctx.fill();

      // Impact value text
      this.ctx.fillStyle = side === 'leftFoot' ? '#a1681a' : '#144CD4';
      this.ctx.textAlign = 'center';
      this.ctx.fillText(
        `${this.phases[side].impact} kN`,
        this.pos.x[side][this.phases[side].angleType] + this.pos.xPad[side],
        this.pos.chartStructure.endY + arrow.lineHeight + arrow.head.height + letterSize
      );

      // Circle
      this.ctx.fillStyle = '#ffffff';
      this.ctx.beginPath();
      this.ctx.arc(
        this.pos.x[side][this.phases[side].angleType] + this.pos.xPad[side],
        this.pos.chartStructure.endY,
        circle.radius,
        0,
        2 * Math.PI
      );
      this.ctx.fill();
      this.ctx.stroke();
    }
  }

  clean() {
    // Store the current transformation matrix
    this.ctx.save();

    // Use the identity matrix while clearing the canvas
    this.ctx.setTransform(1, 0, 0, 1, 0, 0);
    this.ctx.clearRect(0, 0, this.canvas.width, this.canvas.height);

    // Restore the transform
    this.ctx.restore();
    this.ctx.clearRect(0, 0, this.canvas.width, this.canvas.height);
  }
}
