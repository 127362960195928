<template>
  <div class="document v-application">
    <ExportationViewerPage :total="pageTotal" page="1">
      <ExportationFrameHeader class="top-margin-lg" v-bind="{ ...headerInformations }" />

      <ExportationFrameComparison
        v-bind="{ analysis, mode }"
        class="top-margin-lg bottom-margin-lg"
        :chart="ChartRehabContainerBanner"
        rehab
      />

      <ExportationFrameComments
        v-if="mode === 'CMJ' || mode === 'triple-hop'"
        @setComments="setComments($event)"
        @setActions="setActions($event)"
        :height="400"
        class="mb-10"
      />
    </ExportationViewerPage>

    <!--Triple HOP-->
    <template v-if="mode === 'triple-hop'">
      <ExportationViewerPage :total="pageTotal" page="2">
        <ExportationFrameJumpingChartTripleHopJumpProfile
          :aggregates="analysis.first.metrics.aggregates"
          :display-metrics="analysis.first.display_metrics"
          :segments="analysis.first.metrics.segment"
          :activity-date="analysis.first.createdAt"
          :isAdvancedMode="advancedMode"
        />
      </ExportationViewerPage>
      <ExportationViewerPage :total="pageTotal" page="3">
        <ExportationFrameJumpingChartTripleHopJumpProfile
          :aggregates="analysis.second.metrics.aggregates"
          :display-metrics="analysis.second.display_metrics"
          :segments="analysis.second.metrics.segment"
          :activity-date="analysis.second.createdAt"
          :isAdvancedMode="advancedMode"
        />
      </ExportationViewerPage>
      <ExportationViewerPage :total="pageTotal" page="4">
        <ExportationFrameJumpingChartTripleHopGeneralParameters
          v-if="!advancedMode"
          :aggregates="analysis.first.metrics.aggregates"
          :display-metrics="analysis.first.display_metrics"
          :segments="analysis.first.metrics.segment"
          :activity-date="analysis.first.createdAt"
        />
        <ExportationFrameJumpingChartTripleHopGeneralParametersAdvanced
          v-else
          :aggregates="analysis.first.metrics.aggregates"
          :display-metrics="analysis.first.display_metrics"
          :segments="analysis.first.metrics.segment"
          :activity-date="analysis.first.createdAt"
          page="3"
        />
      </ExportationViewerPage>
      <ExportationViewerPage :total="pageTotal" page="5">
        <ExportationFrameJumpingChartTripleHopGeneralParameters
          v-if="!advancedMode"
          :aggregates="analysis.second.metrics.aggregates"
          :display-metrics="analysis.second.display_metrics"
          :segments="analysis.second.metrics.segment"
          :activity-date="analysis.second.createdAt"
        />
        <ExportationFrameJumpingChartTripleHopGeneralParametersAdvanced
          v-else
          :aggregates="analysis.second.metrics.aggregates"
          :display-metrics="analysis.second.display_metrics"
          :segments="analysis.second.metrics.segment"
          :activity-date="analysis.second.createdAt"
          page="3"
        />
      </ExportationViewerPage>
    </template>

    <!--CMJ-->
    <template v-if="mode === 'CMJ'">
      <ExportationViewerPage :total="pageTotal" page="2">
        <span class="export-comparison-chart-title top-margin">{{
          $t('commons.standards.jump-profile')
        }}</span>
        <ExportationFrameComparison
          v-bind="{ analysis, mode }"
          class="mb-5"
          :chart="ExportationFrameJumpingChartCMJJumpProfile"
          rehab
        />
      </ExportationViewerPage>
      <ExportationViewerPage :total="pageTotal" page="3">
        <ExportationFrameJumpingChartCMJGeneralParameters
          :aggregates="analysis.first.metrics.aggregates"
          :display-metrics="analysis.first.display_metrics"
          :segments="analysis.first.metrics.segment"
          :activity-date="analysis.first.createdAt"
          :isAdvancedMode="advancedMode"
          class="mb-10"
        />
      </ExportationViewerPage>
      <ExportationViewerPage :total="pageTotal" page="4">
        <ExportationFrameJumpingChartCMJGeneralParameters
          :aggregates="analysis.second.metrics.aggregates"
          :display-metrics="analysis.second.display_metrics"
          :segments="analysis.second.metrics.segment"
          :activity-date="analysis.second.createdAt"
          :isAdvancedMode="advancedMode"
          class="mb-10"
        />
      </ExportationViewerPage>
    </template>

    <!--SIDE HOP-->
    <template v-if="mode === 'side-hop'">
      <ExportationViewerPage :total="pageTotal" page="2">
        <span class="export-comparison-chart-title top-margin">
          {{ $t('commons.standards.cadence') }}
          {{ $t('commons.standards.evolution') }}
        </span>
        <ExportationFrameComparison
          v-bind="{ analysis, mode }"
          :chart="ChartRehabSideHopCadenceEvolution"
          rehab
        />
      </ExportationViewerPage>
      <ExportationViewerPage :total="pageTotal" page="3">
        <span class="export-comparison-chart-title top-margin">{{
          $t('commons.standards.jump-profile')
        }}</span>
        <ExportationFrameComparison
          v-bind="{ analysis, mode }"
          :chart="ExportationFrameJumpingChartSideHopJumpProfile"
          rehab
        />
      </ExportationViewerPage>
      <ExportationViewerPage :total="pageTotal" page="4">
        <ExportationFrameJumpingChartSideHopGeneralParameters
          :aggregates="analysis.first.metrics.aggregates"
          :display-metrics="analysis.first.display_metrics"
          :activity-date="analysis.first.createdAt"
          :page="advancedMode ? '1' : '2'"
        />
      </ExportationViewerPage>
      <ExportationViewerPage :total="pageTotal" page="5">
        <ExportationFrameJumpingChartSideHopGeneralParameters
          :aggregates="analysis.second.metrics.aggregates"
          :display-metrics="analysis.second.display_metrics"
          :activity-date="analysis.second.createdAt"
          :page="advancedMode ? '1' : '2'"
        />
      </ExportationViewerPage>
      <ExportationViewerPage :total="pageTotal" page="6">
        <ExportationFrameComments
          @setComments="setComments($event)"
          @setActions="setActions($event)"
          :height="400"
        />
      </ExportationViewerPage>
    </template>

    <!--SINGLE HOP-->
    <template v-if="mode === 'single-hop'">
      <ExportationViewerPage :total="pageTotal" page="2">
        <span class="export-comparison-chart-title top-margin">{{
          $t('commons.standards.jump-profile')
        }}</span>
        <ExportationFrameComparison
          v-bind="{ analysis, mode }"
          class="mb-5"
          :chart="ExportationFrameJumpingChartSingleHopJumpProfile"
          rehab
        />
      </ExportationViewerPage>
      <ExportationViewerPage :total="pageTotal" page="3">
        <ExportationFrameJumpingChartSingleHopGeneralParameters
          :aggregates="analysis.first.metrics.aggregates"
          :display-metrics="analysis.first.display_metrics"
          :segments="analysis.first.metrics.segment"
          :activity-date="analysis.first.createdAt"
          :isAdvancedMode="advancedMode"
        />
      </ExportationViewerPage>
      <ExportationViewerPage :total="pageTotal" page="4">
        <ExportationFrameJumpingChartSingleHopGeneralParameters
          :aggregates="analysis.second.metrics.aggregates"
          :display-metrics="analysis.second.display_metrics"
          :segments="analysis.second.metrics.segment"
          :activity-date="analysis.second.createdAt"
          :isAdvancedMode="advancedMode"
        />
      </ExportationViewerPage>

      <ExportationViewerPage :total="pageTotal" page="5">
        <ExportationFrameComments
          @setComments="setComments($event)"
          @setActions="setActions($event)"
          :height="300"
        />
      </ExportationViewerPage>
    </template>
  </div>
</template>

<script>
// Import vendors ----------------------------------------------------------------------------------
import { defineComponent, computed } from '@vue/composition-api';
// Import utils ------------------------------------------------------------------------------------
import { useRehab } from '@/utils/rehab.utils';
// Import components -------------------------------------------------------------
import ExportationViewerPage from '@/components/exportation/ExportationViewerPage.vue';
import ExportationFrameHeader from '@/components/exportation/frames/ExportationFrameHeader.vue';
import ExportationFrameComments from '@/components/exportation/frames/ExportationFrameComments.vue';
import ExportationFrameComparison from '@/components/exportation/frames/ExportationFrameComparison.vue';
// Charts
import ChartRehabContainerBanner from '@/components/charts/rehab/containers/ChartRehabContainerBanner.vue';
import ExportationFrameJumpingChartTripleHopJumpProfileSummary from '@/components/exportation/frames/jumping/charts/ExportationFrameJumpingChartTripleHopJumpProfileSummary.vue';
import ExportationFrameJumpingChartCMJJumpProfile from '@/components/exportation/frames/jumping/charts/ExportationFrameJumpingChartCMJJumpProfile.vue';
import ChartRehabSideHopCadenceEvolution from '@/components/charts/rehab/ChartRehabSideHopCadenceEvolution.vue';
import ExportationFrameJumpingChartTripleHopJumpProfile from '@/components/exportation/frames/jumping/charts/ExportationFrameJumpingChartTripleHopJumpProfile.vue';
import ExportationFrameJumpingChartTripleHopGeneralParameters from '@/components/exportation/frames/jumping/charts/ExportationFrameJumpingChartTripleHopGeneralParameters.vue';
import ExportationFrameJumpingChartCMJGeneralParameters from '@/components/exportation/frames/jumping/charts/ExportationFrameJumpingChartCMJGeneralParameters.vue';
import ExportationFrameJumpingChartSingleHopGeneralParameters from '@/components/exportation/frames/jumping/charts/ExportationFrameJumpingChartSingleHopGeneralParameters.vue';
import ExportationFrameJumpingChartSideHopGeneralParameters from '@/components/exportation/frames/jumping/charts/ExportationFrameJumpingChartSideHopGeneralParameters.vue';
import ExportationFrameJumpingChartSideHopJumpProfile from '@/components/exportation/frames/jumping/charts/ExportationFrameJumpingChartSideHopJumpProfile.vue';
import ExportationFrameJumpingChartTripleHopGeneralParametersAdvanced from '@/components/exportation/frames/jumping/charts/ExportationFrameJumpingChartTripleHopGeneralParametersAdvanced.vue';
import ExportationFrameJumpingChartSingleHopJumpProfile from '@/components/exportation/frames/jumping/charts/ExportationFrameJumpingChartSingleHopJumpProfile.vue';
// -----------------------------------------------------------------------------

export default defineComponent({
  name: 'ExportationFrameJumpingComparison',
  components: {
    ExportationViewerPage,
    ExportationFrameHeader,
    ExportationFrameComments,
    ExportationFrameComparison,
    // Charts
    ExportationFrameJumpingChartTripleHopJumpProfile,
    ExportationFrameJumpingChartCMJGeneralParameters,
    ExportationFrameJumpingChartSingleHopGeneralParameters,
    ExportationFrameJumpingChartSideHopGeneralParameters,
    ExportationFrameJumpingChartTripleHopGeneralParameters,
    ExportationFrameJumpingChartTripleHopGeneralParametersAdvanced
  },
  props: {
    analysis: {
      type: Object,
      required: true
    },
    advancedMode: {
      type: Boolean,
      required: true
    },
    pageTotal: {
      type: Number,
      required: true,
      default: 0
    },
    headerInformations: {
      type: Object,
      required: true
    }
  },
  setup(properties) {
    const { getMode } = useRehab();
    const mode = computed(() => getMode(properties.analysis.first._scenario.key));

    return {
      mode,
      // Components
      ChartRehabContainerBanner,
      ExportationFrameJumpingChartTripleHopJumpProfileSummary,
      ExportationFrameJumpingChartCMJJumpProfile,
      ChartRehabSideHopCadenceEvolution,
      ExportationFrameJumpingChartTripleHopJumpProfile,
      ExportationFrameJumpingChartTripleHopGeneralParameters,
      ExportationFrameJumpingChartCMJGeneralParameters,
      ExportationFrameJumpingChartSingleHopGeneralParameters,
      ExportationFrameJumpingChartSideHopGeneralParameters,
      ExportationFrameJumpingChartSideHopJumpProfile,
      ExportationFrameJumpingChartTripleHopGeneralParametersAdvanced,
      ExportationFrameJumpingChartSingleHopJumpProfile
    };
  }
});
</script>

<style lang="scss" scoped>
.top-margin {
  margin-top: 30px;
  &-sm {
    margin-top: 20px;
  }
  &-lg {
    margin-top: 50px;
  }
}
.bottom-margin {
  margin-top: 30px;
  &-sm {
    margin-top: 20px;
  }
  &-lg {
    margin-top: 50px;
  }
}
.align-row {
  display: grid;
  grid-template-columns: auto auto;
  grid-gap: 50px;
}
.export-comparison-chart-title {
  font-size: 15px;
  font-weight: 500;
}
</style>
