








// Import vendors ----------------------------------------------------------------------------------
import { defineComponent } from '@vue/composition-api';
// Import components -------------------------------------------------------------------------------
import ChartContainer from '@/components/charts/ChartContainer.vue';
// -------------------------------------------------------------------------------------------------

export default defineComponent({
  name: 'ChartRunningWidgetAnalysisMode',
  components: {
    ChartContainer
  },
  props: {
    analysisMode: {
      type: String,
      default: 'outdoor'
    }
  }
});
