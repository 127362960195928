






















// Import vendors ----------------------------------------------------------------------------------
import { defineComponent } from '@vue/composition-api';
// Import plugins ----------------------------------------------------------------------------------
import { usePodocoreModule } from '@/plugins/podocore';
import { TranslateResult } from 'vue-i18n';
// Import utils ------------------------------------------------------------------------------------
import { usePatient } from '@/utils/patient.utils';
// Import components -------------------------------------------------------------------------------
import PatientNavbarItem from '@/components/patient/PatientNavbarItem.vue';
// -------------------------------------------------------------------------------------------------

export type NavItem = {
  title: TranslateResult;
  hash: string;
};

export default defineComponent({
  name: 'PatientNavbar',
  components: {
    PatientNavbarItem
  },
  setup(_, { root }) {
    const { data: patient } = usePatient();
    const busModule = usePodocoreModule('bus');

    const navItems: NavItem[] = [
      {
        title: root.$t('commons.word.dashboard'),
        hash: '#dashboard'
      },
      {
        title: root.$t('commons.word.patient-file'),
        hash: '#patient-file'
      },
      {
        title: root.$t('commons.word.practitioner-analysis'),
        hash: '#practitioner-analysis'
      },
      {
        title: root.$t('commons.standards.evolution'),
        hash: '#evolution'
      }
    ];

    function editPatient() {
      busModule.publish(
        busModule.events.openDialog({
          id: 'patient-update',
          meta: { patientCuid: patient.value?.cuid }
        })
      );
    }

    return {
      // Values
      patient,
      navItems,
      // Methods
      editPatient
    };
  }
});
