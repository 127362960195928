
































// Import vendors ----------------------------------------------------------------------------------
import { defineComponent, computed } from '@vue/composition-api';
// Import plugins ----------------------------------------------------------------------------------
import moment from 'moment';
import { useUnit } from '@/utils/unit.utils';
// Import helpers ----------------------------------------------------------------------------------
import { rounded } from '@/helpers/conversion.helper';
// -------------------------------------------------------------------------------------------------

export default defineComponent({
  name: 'ExportationFramePatient',
  props: {
    patient: {
      type: Object,
      required: true
    },
    analysis: {
      type: Object,
      required: true
    },
    comparisonMode: {
      type: Boolean
    }
  },
  setup(properties) {
    const age = computed(() => moment().diff(moment(properties.patient.infos.birthDate), 'years'));

    const gender = computed(() =>
      properties.patient.infos.gender.toString() === '1'
        ? 'commons.standards.male'
        : 'commons.standards.female'
    );

    const currentUnit = useUnit().currentUnit();

    return {
      age,
      gender,
      currentUnit,
      rounded
    };
  }
});
