




































// Import vendors ----------------------------------------------------------------------------------
import { defineComponent, computed, PropType } from '@vue/composition-api';
import { get } from 'lodash';
// Import plugins ----------------------------------------------------------------------------------
import { usePodocoreModuleService } from '@/plugins/podocore';
// Import utils ------------------------------------------------------------------------------------
import { getCountries } from '@/utils/i18n.utils';
// Import types ------------------------------------------------------------------------------------
import { Entity } from '@/plugins/podocore/helpers/repositories.helper';
// -------------------------------------------------------------------------------------------------

export default defineComponent({
  name: 'ExportationFrameDoctor',
  props: {
    showEmail: {
      type: Boolean,
      required: true
    },
    showPhone: {
      type: Boolean,
      required: true
    },
    workspace: {
      type: Object,
      required: true
    }
  },
  setup(properties) {
    // Services
    const authService = usePodocoreModuleService('auth');
    const doctorService = usePodocoreModuleService('doctor');

    const countries = getCountries();

    const doctor = computed(() => {
      return get(doctorService, 'state.value.context.doctor');
    });

    const professions = computed(() => {
      let professions = get(doctor.value, 'profile.professions', []);
      return professions;
    });

    const address = computed(() => {
      let address = properties.workspace
        ? [
            get(properties.workspace, 'address.zipCode', null),
            get(properties.workspace, 'address.city', null),
            get(properties.workspace, 'address.state', null),
            get(properties.workspace, 'address.country', null)
          ]
            .filter(Boolean)
            .join(', ')
        : null;

      if (address) address = truncate(address, 50);

      return address;
    });

    const formatEmail = computed(() => {
      let email = get(authService, 'state.value.context.user.attributes.email', null);
      if (email) email = truncate(email, 50);
      return email;
    });

    function truncate(value: string, max: number) {
      return `${value.slice(0, max)} ${value.length >= 50 ? '...' : ''}`;
    }

    return {
      // Values
      doctor,
      professions,
      address,
      formatEmail
    };
  }
});
