var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('CompositeDialog',{ref:"rDialog",staticClass:"dialog__export__pdf",attrs:{"id":_vm.dialogId,"persistent":"","fullscreen":"","card.tile":""},scopedSlots:_vm._u([{key:"title",fn:function(){return [(_vm.form)?_c('div',{staticClass:"dialog__export__pdf__banner"},[_c('section',[_c('FormFieldCheckbox',{attrs:{"field":_vm.form.advanced,"label":_vm.$t('commons.standards.advanced'),"hide-details":""}}),_c('FormFieldCheckbox',{attrs:{"field":_vm.form.showEmail,"label":_vm.$tc('commons.standards.email'),"hide-details":""}}),_c('FormFieldCheckbox',{attrs:{"field":_vm.form.showPhone,"label":_vm.$tc('commons.standards.phone'),"hide-details":""}})],1),(_vm.scenario.startsWith('jumping'))?_c('VAlert',{attrs:{"type":"info","outlined":"","text":"","dense":""},scopedSlots:_vm._u([{key:"prepend",fn:function(){return [_c('GlobalIcon',{staticClass:"mr-2",attrs:{"library":"solid","icon":"exclamation-circle","color":"primary"}})]},proxy:true}],null,false,1745214904)},[_c('span',{staticClass:"caption pb-1",staticStyle:{"line-height":"unset"}},[_vm._v(_vm._s(_vm.$t('export.activate-background')))])]):_vm._e(),_c('VBtn',{staticClass:"text-unset",attrs:{"loading":_vm.printDialogIsOpened,"text":""},on:{"click":function($event){return _vm.print()}}},[_c('GlobalIcon',{staticClass:"mr-1",attrs:{"icon":"file-pdf","color":"black"}}),_vm._v(" "+_vm._s(_vm.$t('commons.actions.export'))+" ")],1)],1):_vm._e()]},proxy:true}])},[(_vm.form)?_c('ExportationViewer',_vm._b({ref:"rFrame"},'ExportationViewer',{
      scenario: _vm.scenario,
      analysis: _vm.analysis,
      patient: _vm.patient,
      pending: _vm.printDialogIsOpened,
      mode: _vm.form.advanced.value ? 'advanced' : 'normal',
      showEmail: _vm.form.showEmail.value,
      showPhone: _vm.form.showPhone.value,
      workspace: _vm.currentWorkspace,
      patient: _vm.patient,
      comparisonMode: _vm.comparisonMode
    },false)):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }