





















// Import vendors ----------------------------------------------------------------------------------
import { defineComponent, computed, PropType } from '@vue/composition-api';
// Import utils ------------------------------------------------------------------------------------
import { useRehab, TRehabMode } from '@/utils/rehab.utils';
// -------------------------------------------------------------------------------------------------

export default defineComponent({
  name: 'ExportationFrameComparison',
  props: {
    analysis: {
      type: Object,
      required: true
    },
    chart: {
      type: Object,
      required: true
    },
    options: {
      type: Object,
      required: false
    },
    align: {
      type: String as PropType<'row' | 'column'>,
      default: 'column'
    },
    center: {
      type: Boolean
    },
    rehab: {
      type: Boolean
    },
    mode: {
      type: String as PropType<TRehabMode>,
      required: false,
      default: 'triple-hop'
    }
  },
  setup(properties) {
    const aggregates = computed(() => {
      return {
        first: properties.analysis.first.metrics.aggregates,
        second: properties.analysis.second.metrics.aggregates
      };
    });
    const displayMetrics = computed(() => {
      return {
        first: properties.rehab
          ? properties.analysis.first.display_metrics
          : properties.analysis.first.display_metrics.aggregates,
        second: properties.rehab
          ? properties.analysis.second.display_metrics
          : properties.analysis.second.display_metrics.aggregates
      };
    });
    const norms = computed(() => {
      return {
        first: properties.analysis.first.metrics.norms,
        second: properties.analysis.second.metrics.norms
      };
    });

    // Rehab
    const { getActiveWidgets } = useRehab();
    const segments = computed(() => {
      return {
        first: properties.analysis.first.metrics?.segment,
        second: properties.analysis.second.metrics?.segment
      };
    });
    const activeWidgets = computed(() => getActiveWidgets(properties.mode));

    return {
      aggregates,
      displayMetrics,
      norms,
      // Rehab
      segments,
      activeWidgets
    };
  }
});
