













// Import vendors ----------------------------------------------------------------------------------
import { defineComponent, computed } from '@vue/composition-api';
// Import components -------------------------------------------------------------------------------
import ChartEvolutionContainer from '@/components/charts/ChartEvolutionContainer.vue';
// Import plugins ----------------------------------------------------------------------------------
import i18n from '@/plugins/i18n';
// Import utils - ----------------------------------------------------------------------------------
import { useChart, EThemeColor } from '@/utils/charts.utils';
import { EUnitKey } from '@/utils/parameter-units.utils';
// Import types ------------------------------------------------------------------------------------
import type { TDataStandard } from '@/components/patient/PatientEvolution.vue';
// -------------------------------------------------------------------------------------------------

export default defineComponent({
  name: 'ChartEvolutionStanceTime',
  components: {
    ChartEvolutionContainer
  },
  props: {
    manifest: {
      type: Array,
      required: true
    },
    stanceTime: {
      type: Object
    },
    proportionStanceTime: {
      type: Object
    },
    doubleTime: {
      type: Object
    },
    proportionDoubleTime: {
      type: Object
    },
    norms: {
      type: Object
    },
    relativeNorms: {
      type: Object
    },
    relative: {
      type: Boolean,
      required: true
    },
    doubleType: {
      type: String,
      required: true
    }
  },
  setup(properties) {
    const {
      evolutionChartStructure,
      getSideData,
      getGlobalData,
      getSideDataVariations,
      getSerieItemStructure,
      getNormsStructure
    } = useChart();
    const title = 'commons.standards.contact-time';

    const stanceTime: TDataStandard = computed(() =>
      getSideData(properties[properties.relative ? 'proportionStanceTime' : 'stanceTime'])
    );

    const doubleTime = computed(() =>
      getGlobalData(properties[properties.relative ? 'proportionDoubleTime' : 'doubleTime'])
    );

    const dataVariations = computed(() =>
      getSideDataVariations({ left: stanceTime.value.left.values, right: stanceTime.value.right.values })
    );

    const series = computed(() => {
      const names = {
        leftStanceTime: `${i18n.t('commons.standards.contact-time')} (${(
          i18n.t('commons.standards.left') as string
        ).slice(0, 1)})`,
        rightStanceTime: `${i18n.t('commons.standards.contact-time')} (${(
          i18n.t('commons.standards.right') as string
        ).slice(0, 1)})`,
        doubleTime: i18n.t(`commons.standards.double-${properties.doubleType}`) as string
      };
      let data = [
        // Left
        getSerieItemStructure(
          'stance_time_left',
          names.leftStanceTime,
          stanceTime.value.left.values,
          EThemeColor.Chart1
        ),
        getSerieItemStructure(
          'stance_time_left',
          undefined,
          stanceTime.value.left.ranges,
          EThemeColor.Chart1,
          true
        ),
        // Right
        getSerieItemStructure(
          'stance_time_right',
          names.rightStanceTime,
          stanceTime.value.right.values,
          EThemeColor.Chart2
        ),
        getSerieItemStructure(
          'stance_time_right',
          undefined,
          stanceTime.value.right.ranges,
          EThemeColor.Chart2,
          true
        ),
        // Double
        getSerieItemStructure(
          'double_stance_time',
          names.doubleTime,
          doubleTime.value.global,
          EThemeColor.Chart4,
          false,
          {
            visible: false
          }
        ),
        getSerieItemStructure(
          'double_stance_time',
          undefined,
          doubleTime.value.ranges,
          EThemeColor.Chart4,
          true
        )
      ];
      // Have norms
      if (properties.norms)
        data.push(
          getNormsStructure(
            'stance_time_norms',
            (properties.relative ? properties.relativeNorms : properties.norms) as {
              min: number[];
              max: number[];
            }
          )
        );

      return data;
    });

    const chartOptions = computed(() => {
      return {
        ...evolutionChartStructure(
          title,
          properties.manifest,
          properties.relative ? EUnitKey.Percent : EUnitKey.Millisecond,
          0
        ),
        series: series.value
      };
    });

    return {
      title,
      dataVariations,
      chartOptions
    };
  }
});
