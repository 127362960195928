var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"chart",style:(("height: " + _vm.height))},[(!_vm.hideTitle)?_c('div',{staticClass:"chart__title"},[_vm._v(_vm._s(_vm.$t('commons.sentences.angle-pro-sup')))]):_vm._e(),(_vm.analysisDate)?_c('div',{staticClass:"chart__date"},[_vm._v(_vm._s(_vm.$moment(_vm.analysisDate).format('LLL')))]):_vm._e(),_c('div',{staticClass:"chart__content"},[_c('div',{staticClass:"chart"},[(
          _vm.advancedMode &&
          _vm.displayMetrics.aggregates.running_propulsion_time &&
          _vm.displayMetrics.aggregates.running_proportion_propulsion_time &&
          _vm.displayMetrics.aggregates.running_loading_time &&
          _vm.displayMetrics.aggregates.running_proportion_loading_time &&
          _vm.displayMetrics.aggregates.running_pronation_angle_foot_in &&
          _vm.displayMetrics.aggregates.running_pronation_angle_foot_out &&
          _vm.displayMetrics.aggregates.running_pronation_angle_mid
        )?_c('ChartRunningPronationSupinationAnglesAdvanced',_vm._b({attrs:{"relative":false}},'ChartRunningPronationSupinationAnglesAdvanced',{ height: _vm.height, aggregates: _vm.aggregates },false)):_c('div',[(
            _vm.displayMetrics.aggregates.running_pronation_angle_foot_in &&
            _vm.displayMetrics.aggregates.running_pronation_angle_foot_out &&
            _vm.displayMetrics.aggregates.running_pronation_angle_mid
          )?_c('ChartRunningGroupStepsAngles',{attrs:{"values":_vm.aggregates}}):_vm._e()],1)],1)])])}
var staticRenderFns = []

export { render, staticRenderFns }